import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import { Input, Checkbox, Radiobutton, RadiobuttonGroup } from "msteams-ui-components-react";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import * as AdaptiveCards from "adaptivecards";
import { Button, Loader, Dropdown, Text, Icon, IconProps } from "@stardust-ui/react";
import * as microsoftTeams from "@microsoft/teams-js";
import moment from "moment";
import { MSTeamsIcon, MSTeamsIconType, MSTeamsIconWeight, } from "msteams-ui-icons-react";
import { markDownToHtml } from "../../helpers/formattingHelper";
import FileUploader from "./FileUploader/fileUploader";
import CheckBoxWithTooltip from "./CheckBoxWithTooltip/checkBoxWithTooltip";
import "./newMessage.scss";
import "./teamTheme.scss";
import { getDraftNotification, getTeams, createDraftNotification, updateDraftNotification,
    searchUsers, searchGroups, getGroups, getUsers, verifyGroupAccess,
    deleteUsersAudience, getSentNotification, cancelScheduledMessage,
    updateScheduledNotification, } from "../../apis/messageListApi";
import { getInitAdaptiveCard, setCardTitle, setCardImageLink, setCardSummary,
    setCardAuthor, setActions, setCardStyle, setCardVideoLink,
    setCardVideoImageLink, } from "../AdaptiveCard/adaptiveCard";
import { getBaseUrl } from "../../configVariables";
import { ImageUtil } from "../../utility/imageutility";
import MyEditor from "../RichEditor/richEditor";
import { TFunction } from "i18next";
import { AxiosResponse } from "axios";
import { Icon as FluentIcon } from "@fluentui/react/lib/Icon";

type dropdownItem = {
    key: string;
    header: string;
    content: string;
    image: string;
    team: {
        id: string;
    };
    title: string;
};

export interface IAction {
    title: string;
    link: string;
    isEvent: boolean;
}

export interface IDraftMessage {
    id?: string;
    title: string;
    imageLink?: string;
    videoLink?: string;
    videoImageLink?: string,
    summary?: string;
    author: string;
    actions: IAction[];
    buttonTitle?: string;
    buttonLink?: string;
    eventUrl?: string;
    teams: any[];
    rosters: any[];
    groups: any[];
    users: any[];
    usersListFile?: string;
    allUsers: boolean;
    scheduledDate?: Date;
    isScheduled: boolean;
    updateType: string;
    isImportant: boolean;
}

export interface formState {
    title: string;
    summary?: string;
    imageLink?: string;
    videoLink?: string;
    videoImageLink?: string,
    author: string;
    card?: any;
    page: string;
    teamsOptionSelected: boolean;
    rostersOptionSelected: boolean;
    allUsersOptionSelected: boolean;
    groupsOptionSelected: boolean;
    usersOptionSelected: boolean;
    teams?: any[];
    isAllUserDisabled?: boolean;
    groups?: any[];
    users?: any[];
    isScheduled: boolean;
    selectedImportant: boolean;
    exists?: boolean;
    draftIsMount: boolean;
    messageId: string;
    loader: boolean;
    groupAccess: boolean;
    loading: boolean;
    loadingUsers: boolean;
    scheduledMessageDateTime?: Date;
    scheduledMessageString?: string;
    noResultMessage: string;
    noUsersResultMessage: string;
    unstablePinned?: boolean;
    selectedTeamsNum: number;
    selectedRostersNum: number;
    selectedGroupsNum: number;
    usersNum: number;
    selectedRadioBtn: string;
    selectedUpdateType: string;
    selectedTeams: dropdownItem[];
    selectedRosters: dropdownItem[];
    selectedGroups: dropdownItem[];
    selectedUsers: dropdownItem[];
    usersListFile?: string;
    errorImageUrlMessage: string;
    errorVideoUrlMessage: string,
    buttonUrlsError: boolean;
    forceDisableButton: boolean;
    actions: IAction[];
    topRef: React.RefObject<any>;
    isUpdatingScheduledMessage: boolean,
    loadingStates: { [name: string]: boolean },
}

export interface INewMessageProps extends RouteComponentProps, WithTranslation {
    getDraftMessagesList?: any;
}

class NewMessage extends React.Component<INewMessageProps, formState> {
    readonly localize: TFunction;
    private card: any;

    constructor(props: INewMessageProps) {
        super(props);
        initializeIcons();
        this.localize = this.props.t;
        this.card = getInitAdaptiveCard(this.localize);
        this.setDefaultCard(this.card);

        this.state = {
            title: "",
            topRef: React.createRef(),
            summary: "",
            draftIsMount: false,
            author: "",
            imageLink: "",
            videoLink: "",
            videoImageLink: "",
            card: this.card,
            page: "UpdateTypeSelection",
            teamsOptionSelected: false,
            rostersOptionSelected: false,
            allUsersOptionSelected: false,
            groupsOptionSelected: false,
            usersOptionSelected: false,
            messageId: "",
            loader: true, //TODO handle for storybook
            isScheduled: false,
            selectedImportant: false,
            groupAccess: false,
            loading: true,
            loadingUsers: true,
            scheduledMessageDateTime: undefined,
            scheduledMessageString: undefined,
            noResultMessage: "",
            noUsersResultMessage: "",
            unstablePinned: true,
            selectedTeamsNum: 0,
            selectedRostersNum: 0,
            selectedGroupsNum: 0,
            usersNum: 0,
            selectedRadioBtn: "teams",
            selectedUpdateType: "00",
            selectedTeams: [],
            selectedRosters: [],
            users: [],
            selectedGroups: [],
            selectedUsers: [],
            usersListFile: undefined,
            errorImageUrlMessage: "",
            errorVideoUrlMessage: "",
            buttonUrlsError: false,
            forceDisableButton: false,
            actions: [],
            isUpdatingScheduledMessage: false,
            loadingStates: { "teams": false, "users": false, "rosters": false, "groups": false, "onSave": true },

        };
    }

    private GetQueryParameters = () => {
        let queryParams: any = {};
        window.location.search.substr(1).split("&").forEach(function (item) {
            let s = item.split("="),
                k = s[0],
                v = s[1] && decodeURIComponent(s[1]);
            queryParams[k] = v;
        });
        return queryParams;
    }

    public async componentDidMount() {
        microsoftTeams.initialize();

        //- Handle the Esc key
        document.addEventListener("keydown", this.escFunction, false);
        let params = this.props.match && this.props.match.params;
        if (params == undefined) {
            params = {};
        }
        let query = this.GetQueryParameters();
        let scheduledDate = query["date"];
        if (scheduledDate !== undefined) {
            this.setState({
                isScheduled: true,
                scheduledMessageString: moment(scheduledDate).format("YYYY-MM-DDTHH:mm"),
                //scheduledMessageString: new Date(scheduledDate).toISOString().replace('Z',''),
                scheduledMessageDateTime: new Date(scheduledDate)
            });
        }

        this.setGroupAccess();
        this.getTeamList().then(() => {
            if ("id" in params && 'messagetype' in params) {
                let id = params["id"];
                let messagetype = params['messagetype'];
                this.getItem(id, messagetype).then(() => {
                    const selectedTeams = this.makeDropdownItemList(
                        this.state.selectedTeams,
                        this.state.teams
                    );
                    const selectedRosters = this.makeDropdownItemList(
                        this.state.selectedRosters,
                        this.state.teams
                    );
                    this.setState({
                        exists: true,
                        page: "CardCreation",
                        messageId: id,
                        selectedTeams: selectedTeams,
                        selectedRosters: selectedRosters,
                        loadingStates: { ...this.state.loadingStates, "teams": true, "rosters": true },
                        selectedImportant: this.state.selectedImportant
                    });
                });
                this.getGroupData(id).then(() => {
                    const selectedGroups = this.makeDropdownItems(this.state.groups);
                    this.setState({
                        selectedGroups: selectedGroups,
                        loadingStates: { ...this.state.loadingStates, "groups": true }
                    });
                });
                this.getUserData(id).then(() => {
                    const selectedUsers = this.makeDropdownItems(this.state.users);
                    this.setState({
                        selectedUsers: selectedUsers,
                        loadingStates: { ...this.state.loadingStates, "users": true }
                    })
                });
            } else {
                this.setState(
                    {
                        exists: false,
                        loader: false,
                        loadingStates: { ... this.state.loadingStates, "teams": true, "users": true, "rosters": true, "groups": true },
                    }, () => {
                        let adaptiveCard = new AdaptiveCards.AdaptiveCard();
                        AdaptiveCards.AdaptiveCard.onProcessMarkdown = function (text, result) {
                            result.outputHtml = markDownToHtml(text);
                            result.didProcess = true;
                        };

                        let adaptiveCardContainer = document.getElementsByClassName('adaptiveCardContainer');

                        if (adaptiveCardContainer[0] !== null && adaptiveCardContainer[0] !== undefined) {
                            adaptiveCard.parse(this.state.card);
                            let renderedCard = adaptiveCard.render() as HTMLElement;
                            adaptiveCardContainer[0].appendChild(renderedCard);
                            // if (this.state.btnLink) {
                            //     let link = this.state.btnLink;
                            //     adaptiveCard.onExecuteAction = function (action) { window.open(link, '_blank'); };
                            // }
                        }
                    }
                );
            }
        });
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (this.state.exists) {
            this.updateCard();
        }
        if (this.state.page !== prevState.page) {
            //this.state.topRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }

    private makeDropdownItems = (items: any[] | undefined) => {
        const resultedTeams: dropdownItem[] = [];
        if (items) {
            items.forEach((element) => {
                resultedTeams.push({
                    key: element.id,
                    header: element.name,
                    content: element.mail,
                    image: ImageUtil.makeInitialImage(element.name),
                    team: {
                        id: element.id
                    },
                    title: element.name
                });
            });
        }
        return resultedTeams;
    }

    private makeDropdownItemList = (items: any[], fromItems: any[] | undefined) => {
        const dropdownItemList: dropdownItem[] = [];
        items.forEach(element =>
            dropdownItemList.push(
                typeof element !== "string" ? element : {
                    key: fromItems!.find(x => x.id === element).id,
                    header: fromItems!.find(x => x.id === element).name,
                    image: ImageUtil.makeInitialImage(fromItems!.find(x => x.id === element).name),
                    team: {
                        id: element
                    }
                })
        );
        return dropdownItemList;
    }

    public setDefaultCard = (card: any) => {
        const titleAsString = this.localize("TitleText");
        const summaryAsString = this.localize("Summary");
        const authorAsString = this.localize("Author1");
        const buttonTitleAsString = this.localize("ButtonTitle");

        setCardTitle(card, titleAsString);
        let imgUrl = getBaseUrl() + "/image/imagePlaceholder.png";
        setCardImageLink(card, imgUrl);
        setCardVideoLink(card, "");
        setCardSummary(card, summaryAsString);
        setCardAuthor(card, authorAsString);
        setActions(card, [])
        setCardStyle(card, "00");
    }

    private getTeamList = async () => {
        try {
            const response = await getTeams();
            this.setState({
                teams: response.data.teams,
                isAllUserDisabled: response.data.isAllUserDisabled
            });
        } catch (error) {
            return error;
        }
    }

    private getGroupItems() {
        if (this.state.groups) {
            return this.makeDropdownItems(this.state.groups);
        }
        const dropdownItems: dropdownItem[] = [];
        return dropdownItems;
    }

    private getUserItems() {
        if (this.state.users) {
            return this.makeDropdownItems(this.state.users);
        }
        const dropdownItems: dropdownItem[] = [];
        return dropdownItems;
    }

    private setGroupAccess = async () => {
        await verifyGroupAccess().then(() => {
            this.setState({
                groupAccess: true
            });
        }).catch((error) => {
            const errorStatus = error.response.status;
            if (errorStatus === 403) {
                this.setState({
                    groupAccess: false
                });
            }
            else {
                throw error;
            }
        });
    }

    private getGroupData = async (id: number) => {
        try {
            const response = await getGroups(id);
            this.setState({
                groups: response.data
            });
        }
        catch (error) {
            return error;
        }
    }

    private limitButtons = (): boolean => {
        return this.state.actions && this.state.actions.length > 4;
    }

    private addClick = () => {
        let urlsAndEvents = [...this.state.actions, { title: "", link: "", isEvent: false }]
        urlsAndEvents = urlsAndEvents.sort((a, b) => (a === b) ? 0 : a ? 1 : -1); //swap -1 and 1 to change order of event and links
        this.setState({ actions: urlsAndEvents },
            () => {

                setActions(this.card, this.state.actions)
                this.updateCard();
            });
    }

    private anyEventExist = () => {
        if (this.state.actions.find(x => x.isEvent) !== undefined) {
            return true;
        } else { return false; }
    }

    private changeUrl = (i: number, type: string, event: any) => {
        let showDefaultCard = (!event.target.actions && !event.target.value && !this.state.imageLink && !this.state.summary && !this.state.author);
        let actions = [...this.state.actions];


        if (type === "link") {
            actions[i] = { title: actions[i].title ?? "", link: event.target.value, isEvent: false } as IAction;
        } else {
            actions[i] = { title: event.target.value, link: actions[i].link ?? "", isEvent: false } as IAction;
        }

        this.setState({ actions }, () => {

            setActions(this.card, this.state.actions)
            this.updateCard();
        });
    }

    private removeClick = (i: number) => {
        let actions = [...this.state.actions];
        actions.splice(i, 1);

        this.setState({ actions }, () => {
            setActions(this.card, this.state.actions)
            this.updateCard();
        });
    }

    private checkUrl = (url: string): { errorMessage: string, isValid: boolean } => {
        if (/^\s+$|^$/gi.test(url)) {
            return { errorMessage: "Empty URL", isValid: false }
        }
        else if (!(/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url))) {
            return { errorMessage: "Invalid URL", isValid: false };
        } else if (!(/^https:\/\//.test(url))) {
            return { errorMessage: "URL must start with https://", isValid: false };
        } else if (!((url === "") || (url.startsWith("https://") || (url.startsWith("data:image/png;base64,")) || (url.startsWith("data:image/jpeg;base64,")) || (url.startsWith("data:image/gif;base64,"))))) {
            return { errorMessage: "Invalid URL", isValid: false };
        } else {
            return { errorMessage: "", isValid: true };;
        }
    }

    private createUI = () => {
        return this.state.actions.map((el, i) =>
            <div className="dynamicAction__container" key={i}>
                <Input
                    className="inputField"
                    value={el.title || ''}
                    label={"Title #" + (i + 1)}
                    placeholder={"Title"}
                    onChange={this.changeUrl.bind(this, i, "title")}
                    autoComplete="off"
                    required
                />
                <Input
                    className="inputField"
                    value={el.link || ''}
                    label={"URL #" + (i + 1)}
                    placeholder={"URL (Required)"}
                    onChange={this.changeUrl.bind(this, i, "link")}
                    autoComplete="off"
                    errorLabel={this.checkUrl(el.link).errorMessage}
                    required
                />
                <Button onClick={this.removeClick.bind(this, i)}>Remove</Button>
            </div>
        )
    }

    private getUserData = async (id: number) => {
        try {
            const response = await getUsers(id);
            this.setState({
                users: response.data
            });
        }
        catch (error) {
            return error;
        }
    }

    private deleteUsersAudience = async () => {
        try {
            if (this.state.usersListFile) {
                await deleteUsersAudience(this.state.usersListFile);
                this.setState({
                    usersListFile: undefined
                });
            }
        }
        catch (error) {
            return error;
        }
    }

    private getItem = async (id: number, messagetype: string) => {
        try {
            let response;
            if (messagetype === "draft") {
                response = await getDraftNotification(id);

            }
            if (messagetype === "scheduled") {
                response = await getSentNotification(id);
                this.setState({ isUpdatingScheduledMessage: true });
            }
            const draftMessageDetail = response.data;
            let selectedRadioButton = "teams";
            if (draftMessageDetail.rosters.length > 0) {
                selectedRadioButton = "rosters";
            }
            else if (draftMessageDetail.groups.length > 0) {
                selectedRadioButton = "groups";
            }
            else if (draftMessageDetail.allUsers) {
                selectedRadioButton = "allUsers";
            }

            let actions = draftMessageDetail.actions;

            this.setState({
                teamsOptionSelected: draftMessageDetail.teams.length > 0,
                selectedTeamsNum: draftMessageDetail.teams.length,
                rostersOptionSelected: draftMessageDetail.rosters.length > 0,
                selectedRostersNum: draftMessageDetail.rosters.length,
                usersNum: draftMessageDetail.users.length,
                groupsOptionSelected: draftMessageDetail.groups.length > 0,
                usersOptionSelected: draftMessageDetail.users.length > 0 || draftMessageDetail.usersListFile,
                selectedGroupsNum: draftMessageDetail.groups.length,
                selectedRadioBtn: selectedRadioButton,
                selectedTeams: draftMessageDetail.teams,
                selectedRosters: draftMessageDetail.rosters,
                selectedGroups: draftMessageDetail.groups,
                selectedUsers: draftMessageDetail.users,
                usersListFile: draftMessageDetail.usersListFile,
                selectedUpdateType: draftMessageDetail.updateType,
                selectedImportant: draftMessageDetail.isImportant,
                actions: actions,
            });

            if (messagetype === "scheduled") {
                this.setState({
                    scheduledMessageString: moment(draftMessageDetail.scheduledDate).format("YYYY-MM-DDTHH:mm"),
                    scheduledMessageDateTime: draftMessageDetail.scheduledDate,
                    isScheduled: draftMessageDetail.isScheduled
                });

            }

            var card;

            setCardTitle(this.card, draftMessageDetail.title);
            setCardImageLink(this.card, draftMessageDetail.imageLink);
            setCardVideoLink(this.card, draftMessageDetail.videoLink, draftMessageDetail.videoImageLink);
            setCardSummary(this.card, draftMessageDetail.summary);
            setCardAuthor(this.card, draftMessageDetail.author);
            setCardStyle(this.card, draftMessageDetail.updateType);
            setActions(this.card, actions);
            card = this.card;

            this.setState(
                {
                    title: draftMessageDetail.title,
                    summary: draftMessageDetail.summary,
                    draftIsMount: true,
                    imageLink: draftMessageDetail.imageLink,
                    videoLink: draftMessageDetail.videoLink,
                    videoImageLink: draftMessageDetail.videoImageLink,
                    author: draftMessageDetail.author,
                    allUsersOptionSelected: draftMessageDetail.allUsers,
                    loader: false,
                    card: card,
                }, () => {
                    this.updateCard();
                }
            );
        } catch (error) {
            return error;
        }
    }

    public componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    public render(): JSX.Element {
        const downloadIcon: IconProps = { name: "download", size: "medium" };
        if (this.state.loader) {
            return (
                <div className="Loader">
                    <Loader />
                </div>
            );
        } else {
            if (this.state.page === "UpdateTypeSelection") {
                return (
                    <div className="taskModule">
                        <div className="formContainer" style={{ /*backgroundColor: "#F5F5F5",*/ display: "flex", justifyContent: "center", flexDirection: "column" }} ref={this.state.topRef}>
                            <div className="formCentered" >
                                <h1 style={{ fontWeight: 400 }}>TTEC Communicator</h1>
                                <div style={{ width: "400px", maxWidth: "100%" }}>
                                    <h4 style={{ margin: 0, fontWeight: 600, marginBottom: "2rem" }}>Select an option below</h4>
                                    <RadiobuttonGroup
                                        value={this.state.selectedUpdateType}
                                        onSelected={this.onUpdateTypeSelected}>
                                        <Radiobutton name="grouped" value="00" label="Health & Wellness" />
                                        <Radiobutton name="grouped" value="01" label="Diversity, Equity, & Inclusion" />
                                        <Radiobutton name="grouped" value="02" label="Career Development" />
                                        <Radiobutton name="grouped" value="03" label="Global Technology" />
                                        <Radiobutton name="grouped" value="04" label="Our Company" />
                                        <Radiobutton name="grouped" value="05" label="Rewards & Recognition" />
                                        <Radiobutton name="grouped" value="06" label="Culture & Community" />
                                        <Radiobutton name="grouped" value="07" label="Safety & Security" />
                                        <Radiobutton name="grouped" value="08" label="TTEC Trust" />
                                    </RadiobuttonGroup>
                                </div>
                            </div>
                        </div>
                        <div className="footerContainer">
                            <div className="buttonContainer">
                                <Button content={this.localize("Next")} id="saveBtn" onClick={(event: any) => { this.onNext(event, "CardCreation"); }} primary />
                            </div>
                        </div>
                    </div>
                );
            } else if (this.state.page === "CardCreation") {
                return (
                    <div className="taskModule" id="top">
                        <div className="formContainer">
                            <div className="formContentContainer">
                                <Input
                                    className="inputField"
                                    value={this.state.title}
                                    label={this.localize("TitleText")}
                                    placeholder={this.localize("PlaceHolderTitle")}
                                    onChange={this.onTitleChanged}
                                    autoComplete="off"
                                    required
                                />
                                <div className="formContentContainer__image-source-container">
                                    <Input
                                        className="image-source-container__link-input"
                                        value={this.state.imageLink}
                                        label={this.localize("ImageURL")}
                                        placeholder={this.localize("ImageURL")}
                                        onChange={this.onImageLinkChanged}
                                        errorLabel={this.state.errorImageUrlMessage}
                                        autoComplete="off"
                                    />
                                    <Icon
                                        name="info"
                                        title={
                                            "Images must be at most 1024×1024 px, less than 1 MB size, and in PNG, JPEG, or GIF format. Animated GIF is not supported."
                                        }
                                        xSpacing="after"
                                        className="succeeded"
                                        contextMenu="dsd"
                                        outline
                                    />

                                    <FileUploader
                                        buttonIcon={downloadIcon}
                                        buttonText={"Attach image"}
                                        url="/content/uploadImage"
                                        onSuccess={this.attachImage.bind(this)}
                                        acceptedFileTypes=".png,.jpg,.jpeg"
                                    />
                                </div>

                                <div className="formContentContainer__image-source-container">
                                    <Input
                                        className="image-source-container__link-input"
                                        value={this.state.videoLink}
                                        label={this.localize("VideoURL")}
                                        placeholder={this.localize("VideoURL")}
                                        onChange={this.onVideoChanged}
                                        errorLabel={this.state.errorVideoUrlMessage}
                                        autoComplete="off"
                                    />
                                    <Icon name="info" title={"Video must be YouTube/Vimeo link or Supported upload format: MP4, WebM, OGG"} xSpacing="after" className="succeeded" contextMenu="dsd" outline />

                                    <FileUploader
                                        buttonIcon={downloadIcon}
                                        buttonText={"Attach Video"}
                                        url="/content/uploadVideo"
                                        onSuccess={this.attachVideo.bind(this)}
                                        acceptedFileTypes=".mp4,.webm,.ogg"
                                    />
                                </div>

                                <div className="formContentContainer__image-source-container">
                                    <Input
                                        className="image-source-container__link-input"
                                        value={this.state.videoImageLink}
                                        label="Video Image Url"
                                        placeholder="Video Image Url"
                                        onChange={this.onVideoImageLinkChanged}
                                        autoComplete="off"
                                        disabled={true}
                                    />
                                    <Icon name="info" title={"Images must be at most 1024×1024 px, less than 1 MB size, and in PNG, JPEG, or GIF format. Animated GIF is not supported."} xSpacing="after" className="succeeded" contextMenu="dsd" outline />

                                    <FileUploader
                                        buttonIcon={downloadIcon}
                                        buttonText={"Attach Video image"}
                                        url="/content/uploadVideoImage"
                                        onSuccess={this.attachVideoImage.bind(this)}
                                        acceptedFileTypes=".png,.jpg,.jpeg"
                                        disabled={!this.state.videoLink}
                                    />
                                </div>

                                <MyEditor
                                    onSummaryChanged={this.onSummaryChanged}
                                    placeholder={this.localize("Summary")}
                                    draftIsMount={this.state.draftIsMount}
                                    initialValue={this.state.summary}
                                />

                                <Input
                                    className="inputField"
                                    value={this.state.author}
                                    label={this.localize("Author")}
                                    placeholder={this.localize("Author")}
                                    onChange={this.onAuthorChanged}
                                    autoComplete="off"
                                />

                                <div className="formContentContainer__attachEvent">
                                    {!this.limitButtons() && (
                                        <Button type="button" onClick={this.addClick.bind(this)}>
                                            Add a link
                                        </Button>
                                    )}
                                    {this.createUI()}
                                </div>

                                <div className="formContentContainer__attachEvent">
                                    <FileUploader
                                        buttonIcon={downloadIcon}
                                        buttonText={"Attach event details"}
                                        disabled={this.anyEventExist() || this.limitButtons()}
                                        url="/content/uploadEvent"
                                        onSuccess={this.uploadEvent.bind(this)}
                                        acceptedFileTypes=".ics,.ical"
                                    />
                                </div>
                            </div>
                            <div className="adaptiveCardContainer"></div>
                        </div>

                        <div className="footerContainer">
                            <div className="buttonContainer">
                                <Button content={this.state.exists ? "Change Template" : this.localize("Back")} onClick={(event: any) => this.onBack(event, "UpdateTypeSelection")} secondary />
                                <Button content={this.localize("Next")} disabled={this.isNextBtnDisabled()} id="saveBtn" onClick={(event: any) => this.onNext(event, "AudienceSelection")} primary />
                            </div>
                        </div>
                    </div>
                );
            } else if (this.state.page === "AudienceSelection") {
                return (
                    <div className="taskModule">
                        <div className="formContainer">
                            <div className="formContentContainer" ref={this.state.topRef}>
                                <h4>{this.localize("SendHeadingText")}</h4>
                                <RadiobuttonGroup
                                    className="radioBtns"
                                    value={this.state.selectedRadioBtn}
                                    onSelected={this.onGroupSelected}
                                >
                                    <h3 style={{ fontWeight: 600, margin: "0" }}>
                                        Send via chat
                                    </h3>

                                    {!this.state.isAllUserDisabled && (
                                        <>
                                            <Checkbox value={1} label={this.localize("SendToAllUsers")}
                                                checked={this.state.allUsersOptionSelected}
                                                onChecked={(c, v) => this.setState({ allUsersOptionSelected: c })} />
                                            <div className={this.state.allUsersOptionSelected ? "" : "hide"}>
                                                <div className="noteText">
                                                    <Text error content={this.localize("SendToAllUsersNote")} />
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <CheckBoxWithTooltip
                                        label={this.localize("SendToRostersPlaceHolder")}
                                        checked={this.state.rostersOptionSelected}
                                        onChecked={(c: boolean) => this.setState({ rostersOptionSelected: c })}
                                        tooltip="The application must be installed in the Team to send a communication to the Team’s members."
                                    />

                                    {this.state.rostersOptionSelected && !this.state.loadingStates["rosters"] && <Loader />}

                                    <Dropdown
                                        hidden={!this.state.rostersOptionSelected || !this.state.loadingStates["rosters"]}
                                        placeholder={this.localize("SendToRostersPlaceHolder")}
                                        search
                                        multiple
                                        items={this.getItems()}
                                        value={this.state.selectedRosters}
                                        onSelectedChange={this.onRostersChange}
                                        unstable_pinned={this.state.unstablePinned}
                                        noResultsMessage={this.localize("NoMatchMessage")}
                                    />

                                    <CheckBoxWithTooltip
                                        label={this.localize("SendToGroups")}
                                        checked={this.state.groupsOptionSelected}
                                        onChecked={(c: boolean) => this.setState({ groupsOptionSelected: c })}
                                        tooltip="M365 Group, Security Group or Distribution List"
                                    />

                                    <div className={this.state.groupsOptionSelected && !this.state.groupAccess ? "" : "hide"}>
                                        <div className="noteText">
                                            <Text error content={this.localize("SendToGroupsPermissionNote")} />
                                        </div>
                                    </div>

                                    {this.state.groupsOptionSelected && !this.state.loadingStates["groups"] && <Loader />}

                                    <Dropdown
                                        className="hideToggle"
                                        hidden={!this.state.groupsOptionSelected || !this.state.groupAccess || !this.state.loadingStates["groups"]}
                                        placeholder={this.localize("SendToGroupsPlaceHolder")}
                                        search={this.onGroupSearch}
                                        multiple
                                        loading={this.state.loading}
                                        loadingMessage={this.localize("LoadingText")}
                                        items={this.getGroupItems()}
                                        value={this.state.selectedGroups}
                                        onSearchQueryChange={this.onGroupSearchQueryChange}
                                        onSelectedChange={this.onGroupsChange}
                                        noResultsMessage={this.state.noResultMessage}
                                        unstable_pinned={this.state.unstablePinned}
                                    />

                                    <div className={this.state.groupsOptionSelected && this.state.groupAccess ? "" : "hide"}>
                                        <div className="noteText">
                                            <Text error content={this.localize("SendToGroupsNote")} />
                                        </div>
                                    </div>

                                    <CheckBoxWithTooltip
                                        label={"Individual user(s)"}
                                        checked={this.state.usersOptionSelected}
                                        onChecked={(c: boolean) => this.setState({ usersOptionSelected: c })}
                                        tooltip="Enter individual usernames in the search box below OR download the Excel template and upload the list of Email addresses you want to send a communication to."
                                    />

                                    {this.state.usersOptionSelected && !this.state.loadingStates["users"] && <Loader />}

                                    <Dropdown
                                        className="hideToggle"
                                        hidden={!this.state.usersOptionSelected || !this.state.groupAccess || !this.state.loadingStates["users"]}
                                        placeholder={"Type the name or email address of the employee(s)"}
                                        search={this.onUserSearch}
                                        multiple
                                        loading={this.state.loadingUsers}
                                        loadingMessage={this.localize("LoadingText")}
                                        items={this.getUserItems()}
                                        value={this.state.selectedUsers}
                                        onSearchQueryChange={this.onUserSearchQueryChange}
                                        onSelectedChange={this.onUsersChange}
                                        noResultsMessage={this.state.noUsersResultMessage}
                                        unstable_pinned={this.state.unstablePinned}
                                    />

                                    {this.state.usersOptionSelected && (
                                        <div className="formContentContainer__fileUploadContainer">
                                            <div className="formContentContainer__fileUpload">
                                                <div className="formContentContainer__templateDownload">
                                                    <MSTeamsIcon
                                                        tabIndex={0}
                                                        aria-label={MSTeamsIconType.MSExcel}
                                                        iconWeight={MSTeamsIconWeight.Light}
                                                        iconType={MSTeamsIconType.MSExcel} />
                                                    <a target="_blank" href="/Content/Audience List.xlsx">Download template</a>
                                                </div>
                                                <FileUploader
                                                    buttonIcon={downloadIcon}
                                                    buttonText={"Upload users"}
                                                    url="/content/uploadUsersAudience"
                                                    onSuccess={this.uploadUsers.bind(this)}
                                                    acceptedFileTypes=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                />
                                            </div>
                                            {this.state.usersListFile && (
                                                <>
                                                    <a target="_blank" href={"/api/Content/getUsersAudience?fileName=" + this.state.usersListFile}>{this.state.usersListFile}</a>
                                                    <FluentIcon iconName="ChromeClose" style={{ marginLeft: '1rem', cursor: 'pointer', verticalAlign: 'middle' }} onClick={this.deleteUsersAudience} />
                                                </>
                                            )}
                                        </div>
                                    )}

                                    <div className="formContentContainer__selectTeamsArea">
                                        <CheckBoxWithTooltip
                                            style={{ fontWeight: 600, fontSize: "medium" }}
                                            label={"Send to a Team(s)"}
                                            checked={this.state.teamsOptionSelected}
                                            onChecked={(c: boolean) => this.setState({ teamsOptionSelected: c })}
                                            tooltip="The application must be installed in the Team to send a communication into the selected Team’s Channel."
                                        />

                                        <Dropdown
                                            hidden={!this.state.teamsOptionSelected}
                                            placeholder={this.localize("SendToGeneralChannelPlaceHolder")}
                                            search
                                            multiple
                                            items={this.getItems()}
                                            value={this.state.selectedTeams}
                                            onSelectedChange={this.onTeamsChange}
                                            noResultsMessage={this.localize("NoMatchMessage")}
                                        />
                                    </div>



                                    <div>
                                        <h3 style={{ fontWeight: 600, margin: "4rem 0 5px", }}>Schedule message</h3>
                                        <div className="schedulingArea">
                                            <Checkbox value={1} label="Enabled"
                                                checked={this.state.isScheduled}
                                                onChecked={(c, v) => this.setState({ isScheduled: c })} />

                                            <input
                                                value={this.state.scheduledMessageString}
                                                onChange={(e) => this.setState({ scheduledMessageDateTime: new Date(e.target.value), scheduledMessageString: e.target.value, isScheduled: true })}
                                                type="datetime-local"
                                                id="messageDeliveryTime"
                                                min={moment().format("YYYY-MM-DDTHH:mm")}
                                                max={moment()
                                                    .add(6, "M")
                                                    .format("YYYY-MM-DDTHH:mm")}
                                            />

                                        </div>
                                    </div>
                                    <h3 style={{ fontWeight: 600, margin: "2rem 0 5px", }}>Send as important message</h3>
                                    <CheckBoxWithTooltip
                                        label="Enabled"
                                        checked={this.state.selectedImportant}
                                        onChecked={this.onImportantSelected}
                                        tooltip={this.localize('ImportantDescription')}
                                    />
                                </RadiobuttonGroup>
                            </div>

                            <div className="adaptiveCardContainer"></div>
                        </div>

                        <div className="footerContainer">
                            <div className="buttonContainer">
                                <Button
                                    content={this.localize("Back")}
                                    onClick={(event: any) => this.onBack(event, "CardCreation")}
                                    secondary
                                />
                                <Button
                                    content={
                                        this.state.isScheduled
                                            ? "Schedule"
                                            : this.localize("SaveAsDraft")
                                    }
                                    disabled={
                                        this.isSaveBtnDisabled() || this.state.forceDisableButton
                                    }
                                    id="saveBtn"
                                    onClick={this.state.isUpdatingScheduledMessage && !this.state.scheduledMessageDateTime ? this.onCancelScheduledTask : this.onSave}
                                    primary
                                />
                            </div>
                        </div>
                    </div>
                );
            } else {
                return <div>Error</div>;
            }
        }
    }

    private attachImage = (response: AxiosResponse): void => {
        setCardImageLink(this.card, response.data);
        this.setState({ imageLink: response.data, card: this.card });
        this.updateCard();
    }

    private attachVideo = (response: AxiosResponse): void => {
        setCardVideoLink(this.card, response.data, this.state.videoImageLink);
        this.setState({ videoLink: response.data, card: this.card });
        this.updateCard();
    }

    private attachVideoImage = (response: AxiosResponse): void => {
        setCardVideoImageLink(this.card, response.data);
        this.setState({ videoImageLink: response.data, card: this.card });
        this.updateCard();
    }

    private uploadEvent = (response: AxiosResponse): void => {
        let urlsAndEvents = [
            ...this.state.actions,
            { title: "Event details", link: response.data, isEvent: true },
        ];
        urlsAndEvents = urlsAndEvents.sort((a, b) => (a === b ? 0 : a ? 1 : -1)); //swap -1 and 1 to change order of event and links
        this.setState({ actions: urlsAndEvents }, () => {
            setActions(this.card, this.state.actions);
            this.updateCard();
        });
    };

    private uploadUsers = (response: AxiosResponse): void => {
        this.setState({ usersListFile: response.data });
    };

    private onGroupSelected = (value: any) => {
        this.setState({
            selectedRadioBtn: value,
            teamsOptionSelected: value === 'teams',
            rostersOptionSelected: value === 'rosters',
            groupsOptionSelected: value === 'groups',
            allUsersOptionSelected: value === 'allUsers',
            selectedTeams: value === 'teams' ? this.state.selectedTeams : [],
            selectedTeamsNum: value === 'teams' ? this.state.selectedTeamsNum : 0,
            selectedRosters: value === 'rosters' ? this.state.selectedRosters : [],
            selectedRostersNum: value === 'rosters' ? this.state.selectedRostersNum : 0,
            selectedGroups: value === 'groups' ? this.state.selectedGroups : [],
            selectedGroupsNum: value === 'groups' ? this.state.selectedGroupsNum : 0,
            selectedUsers: value === 'users' ? this.state.selectedUsers : [],
            usersNum: value === 'users' ? this.state.usersNum : 0,
        });
    }

    private onUpdateTypeSelected = (value: any) => {
        var card;

        card = this.card;

        this.setState({
            selectedUpdateType: value,
            card: card,
        });
    };

    private onImportantSelected = () => {
        this.setState({
            selectedImportant: !this.state.selectedImportant
        });
    }

    private isSaveBtnDisabled = () => {
        const teamsSelectionIsValid = (this.state.teamsOptionSelected && (this.state.selectedTeamsNum !== 0)) || (!this.state.teamsOptionSelected);
        const rostersSelectionIsValid = (this.state.rostersOptionSelected && (this.state.selectedRostersNum !== 0)) || (!this.state.rostersOptionSelected);
        const groupsSelectionIsValid = (this.state.groupsOptionSelected && (this.state.selectedGroupsNum !== 0)) || (!this.state.groupsOptionSelected);
        const usersSelectionIsValid = (this.state.usersOptionSelected && (this.state.usersNum !== 0) || this.state.usersListFile) || (!this.state.usersOptionSelected);
        const nothingSelected = (!this.state.usersOptionSelected) && (!this.state.teamsOptionSelected) && (!this.state.rostersOptionSelected) && (!this.state.groupsOptionSelected) && (!this.state.allUsersOptionSelected);
        const isScheduledButDateNotSpecified = (this.state.isScheduled) && (!this.state.scheduledMessageDateTime)
        return (!usersSelectionIsValid || !teamsSelectionIsValid || !rostersSelectionIsValid || !groupsSelectionIsValid || nothingSelected || isScheduledButDateNotSpecified)
    }

    private isNextBtnDisabled = () => {
        const title = this.state.title;
        const actions = this.state.actions;
        const buttonUrlsError = actions.length > 0 && actions.some(x => !this.checkUrl(x.link).isValid);
        return !(title && (this.state.errorImageUrlMessage === "") && !buttonUrlsError);
    }

    private getItems = () => {
        const resultedTeams: dropdownItem[] = [];
        if (this.state.teams) {
            let remainingUserTeams = this.state.teams;
            if (this.state.selectedRadioBtn !== "allUsers") {
                if (this.state.selectedRadioBtn === "teams") {
                    this.state.teams.filter(
                        (x) =>
                            this.state.selectedTeams.findIndex((y) => y.team.id === x.id) < 0
                    );
                } else if (this.state.selectedRadioBtn === "rosters") {
                    this.state.teams.filter(
                        (x) =>
                            this.state.selectedRosters.findIndex((y) => y.team.id === x.id) <
                            0
                    );
                }
            }
            remainingUserTeams.forEach((element) => {
                resultedTeams.push({
                    key: element.id,
                    header: element.name,
                    content: element.mail,
                    image: ImageUtil.makeInitialImage(element.name),
                    team: {
                        id: element.id,
                    },
                    title: element.name,
                });
            });
        }
        return resultedTeams;
    }

    private static MAX_SELECTED_TEAMS_NUM: number = 20;
    private static MAX_SELECTED_USER_NUM: number = 20;

    private onTeamsChange = (event: any, itemsData: any) => {
        if (itemsData.value.length > NewMessage.MAX_SELECTED_TEAMS_NUM) return;
        this.setState({
            selectedTeams: itemsData.value,
            selectedTeamsNum: itemsData.value.length,
            //selectedRosters: [],
            //selectedRostersNum: 0,
            //selectedGroups: [],
            //selectedGroupsNum: 0
        });
    }

    private onRostersChange = (event: any, itemsData: any) => {
        if (itemsData.value.length > NewMessage.MAX_SELECTED_TEAMS_NUM) return;
        this.setState({
            selectedRosters: itemsData.value,
            selectedRostersNum: itemsData.value.length,
        });
    }

    private onUsersChange = (event: any, itemsData: any) => {
        if (itemsData.value.length > NewMessage.MAX_SELECTED_USER_NUM) return;
        this.setState({
            selectedUsers: itemsData.value,
            usersNum: itemsData.value.length,
        });
    }

    private onGroupsChange = (event: any, itemsData: any) => {
        this.setState({
            selectedGroups: itemsData.value,
            selectedGroupsNum: itemsData.value.length,
        });
    }

    private onGroupSearch = (itemList: any, searchQuery: string) => {
        const result = itemList.filter(
            (item: { header: string; content: string }) =>
                (item.header &&
                    item.header.toLowerCase().indexOf(searchQuery.toLowerCase()) !==
                    -1) ||
                (item.content &&
                    item.content.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1)
        );
        return result;
    };

    private onUserSearch = (itemList: any, searchQuery: string) => {
        const result = itemList.filter(
            (item: { header: string; content: string; }) => (item.header && item.header.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) ||
                (item.content && item.content.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1),
        )
        return result;
    }

    private onUserSearchQueryChange = async (event: any, itemsData: any) => {
        if (!itemsData.searchQuery) {
            this.setState({
                users: [],
                noUsersResultMessage: "",
            });
        } else if (itemsData.searchQuery && itemsData.searchQuery.length <= 2) {
            this.setState({
                loadingUsers: false,
                noUsersResultMessage: "No matches found.",
            });
        } else if (itemsData.searchQuery && itemsData.searchQuery.length > 2) {
            // handle event trigger on item select.
            const result =
                itemsData.items &&
                itemsData.items.find(
                    (item: { header: string }) =>
                        item.header.toLowerCase() === itemsData.searchQuery.toLowerCase()
                );
            if (result) {
                return;
            }

            this.setState({
                loadingUsers: true,
                noUsersResultMessage: "",
            });

            try {
                const query = encodeURIComponent(itemsData.searchQuery);
                const response = await searchUsers(query);
                this.setState({
                    users: response.data,
                    loadingUsers: false,
                    noUsersResultMessage: "No matches found."
                });
            } catch (error) {
                return error;
            }
        }
    };

    private onGroupSearchQueryChange = async (event: any, itemsData: any) => {
        if (!itemsData.searchQuery) {
            this.setState({
                groups: [],
                noResultMessage: "",
            });
        } else if (itemsData.searchQuery && itemsData.searchQuery.length <= 2) {
            this.setState({
                loading: false,
                noResultMessage: "No matches found.",
            });
        } else if (itemsData.searchQuery && itemsData.searchQuery.length > 2) {
            // handle event trigger on item select.
            const result =
                itemsData.items &&
                itemsData.items.find(
                    (item: { header: string }) =>
                        item.header.toLowerCase() === itemsData.searchQuery.toLowerCase()
                );
            if (result) {
                return;
            }

            this.setState({
                loading: true,
                noResultMessage: "",
            });

            try {
                const query = encodeURIComponent(itemsData.searchQuery);
                const response = await searchGroups(query);
                this.setState({
                    groups: response.data,
                    loading: false,
                    noResultMessage: "No matches found.",
                });
            } catch (error) {
                return error;
            }
        }
    };

    private onSave = () => {
        const selectedTeams: string[] = [];
        const selctedRosters: string[] = [];
        const selectedGroups: string[] = [];
        const selectedUsers: string[] = [];
        if (this.state.teamsOptionSelected) this.state.selectedTeams.forEach(x => selectedTeams.push(x.team.id));
        if (this.state.rostersOptionSelected) this.state.selectedRosters.forEach(x => selctedRosters.push(x.team.id));
        if (this.state.groupsOptionSelected) this.state.selectedGroups.forEach(x => selectedGroups.push(x.team.id));
        if (this.state.usersOptionSelected) this.state.selectedUsers.forEach(x => selectedUsers.push(x.team.id));

        const draftMessage: IDraftMessage = {
            id: this.state.messageId,
            title: this.state.title,
            imageLink: this.state.imageLink,
            videoLink: this.state.videoLink,
            videoImageLink: this.state.videoImageLink,
            summary: this.state.summary,
            author: this.state.author,
            teams: selectedTeams,
            rosters: selctedRosters,
            groups: selectedGroups,
            users: selectedUsers,
            usersListFile: this.state.usersListFile,
            allUsers: this.state.allUsersOptionSelected,
            isScheduled: this.state.isScheduled,
            isImportant: this.state.selectedImportant,
            scheduledDate: this.state.scheduledMessageDateTime,
            updateType: this.state.selectedUpdateType,
            actions: this.state.actions,
        };

        this.setState({ forceDisableButton: !this.state.forceDisableButton, loadingStates: { ...this.state.loadingStates, "onSave": false } })

        if (this.state.isUpdatingScheduledMessage) {
            this.editScheduledMessage(draftMessage).then(() => {
                this.setState({ loadingStates: { ...this.state.loadingStates, "onSave": true } })
                microsoftTeams.tasks.submitTask();
            });
        } else if (this.state.exists) {
            this.editDraftMessage(draftMessage).then(() => {
                this.setState({ loadingStates: { ...this.state.loadingStates, "onSave": true } })
                microsoftTeams.tasks.submitTask();
            });
        } else {
            this.setState({ loadingStates: { ...this.state.loadingStates, "onSave": true } })
            this.postDraftMessage(draftMessage).then(() => {
                microsoftTeams.tasks.submitTask();
            });
        }
    }

    private editDraftMessage = async (draftMessage: IDraftMessage) => {
        try {
            await updateDraftNotification(draftMessage);
        } catch (error) {
            return error;
        }
    };

    private editScheduledMessage = async (scheduledMessage: IDraftMessage) => {
        try {
            await updateScheduledNotification(scheduledMessage);
        } catch (error) {
            return error;
        }
    }

    private postDraftMessage = async (draftMessage: IDraftMessage) => {
        try {
            await createDraftNotification(draftMessage);
        } catch (error) {
            throw error;
        }
    }

    public escFunction(event: any) {
        if (event.keyCode === 27 || (event.key === "Escape")) {
            microsoftTeams.tasks.submitTask();
        }
    }

    private onNext = (event: any, page: string) => {
        this.setState({
            page
        }, () => {
            this.updateCard();
        });
    }

    private onBack = (event: any, page: string) => {
        this.setState({
            page
        }, () => {
            this.updateCard();
        });
    }

    private onTitleChanged = (event: any) => {
        let showDefaultCard = (!event.target.value && !this.state.imageLink && !this.state.videoLink && !this.state.summary && !this.state.author);
        setCardTitle(this.card, event.target.value);
        setCardImageLink(this.card, this.state.imageLink);
        setCardVideoLink(this.card, this.state.videoLink, this.state.videoImageLink);
        setCardSummary(this.card, this.state.summary);
        setCardAuthor(this.card, this.state.author);
        setActions(this.card, this.state.actions);

        this.setState(
            {
                title: event.target.value,
                card: this.card
            },
            () => {
                if (showDefaultCard) {
                    this.setDefaultCard(this.card);
                }
                this.updateCard();
            }
        );
    };

    private onImageLinkChanged = (event: any) => {
        let url = event.target.value.toLowerCase();
        if (
            !(
                url === "" ||
                url.startsWith("https://") ||
                url.startsWith("data:image/png;base64,") ||
                url.startsWith("data:image/jpeg;base64,") ||
                url.startsWith("data:image/gif;base64,")
            )
        ) {
            this.setState({
                errorImageUrlMessage: "URL must start with https://"
            });
        } else {
            this.setState({
                errorImageUrlMessage: ""
            });
        }

        let showDefaultCard = (!this.state.title && !event.target.value && !this.state.videoLink && !this.state.summary && !this.state.author);
        setCardTitle(this.card, this.state.title);
        setCardImageLink(this.card, event.target.value);
        setCardVideoLink(this.card, this.state.videoLink, this.state.videoImageLink);
        setCardSummary(this.card, this.state.summary);
        setCardAuthor(this.card, this.state.author);
        setActions(this.card, this.state.actions);

        this.setState(
            {
                imageLink: event.target.value,
                card: this.card
            },
            () => {
                if (showDefaultCard) {
                    this.setDefaultCard(this.card);
                }
                this.updateCard();
            }
        );
    };

    private onVideoChanged = (event: any) => {
        let url = event.target.value.toLowerCase();
        if (!((url === "") || (url.startsWith("https://")))) {
            this.setState({
                errorVideoUrlMessage: "URL must start with https://"
            });
        } else {
            this.setState({
                errorVideoUrlMessage: ""
            });
        }

        let showDefaultCard = (!this.state.title && !this.state.imageLink && !event.target.value && !this.state.summary && !this.state.author);
        setCardTitle(this.card, this.state.title);
        setCardImageLink(this.card, this.state.imageLink);
        setCardVideoLink(this.card, event.target.value, this.state.videoImageLink);
        setCardSummary(this.card, this.state.summary);
        setCardAuthor(this.card, this.state.author);
        setActions(this.card, this.state.actions)

        this.setState({
            videoLink: event.target.value,
            card: this.card
        }, () => {
            if (showDefaultCard) {
                this.setDefaultCard(this.card);
            }
            this.updateCard();
        });
    }

    private onVideoImageLinkChanged = (event: any) => {
        let url = event.target.value.toLowerCase();
        if (!((url === "") || (url.startsWith("https://") || (url.startsWith("data:image/png;base64,")) || (url.startsWith("data:image/jpeg;base64,")) || (url.startsWith("data:image/gif;base64,"))))) {
            this.setState({
                errorImageUrlMessage: "URL must start with https://"
            });
        } else {
            this.setState({
                errorImageUrlMessage: ""
            });
        }

        let showDefaultCard = (!this.state.title && !this.state.imageLink && !this.state.videoLink && !this.state.summary && !this.state.author);
        setCardTitle(this.card, this.state.title);
        setCardImageLink(this.card, this.state.imageLink);
        setCardVideoLink(this.card, this.state.videoLink, event.target.value);
        setCardSummary(this.card, this.state.summary);
        setCardAuthor(this.card, this.state.author);
        setActions(this.card, this.state.actions);

        this.setState({
            videoImageLink: event.target.value,
            card: this.card
        }, () => {
            if (showDefaultCard) {
                this.setDefaultCard(this.card);
            }
            this.updateCard();
        });
    }

    private onSummaryChanged = (markdown: any) => {
        let showDefaultCard = (!this.state.title && !this.state.imageLink && !this.state.videoLink && !markdown && !this.state.author);
        setCardTitle(this.card, this.state.title);
        setCardImageLink(this.card, this.state.imageLink);
        setCardVideoLink(this.card, this.state.videoLink, this.state.videoImageLink);
        setCardSummary(this.card, markdown);
        setCardAuthor(this.card, this.state.author);
        setActions(this.card, this.state.actions);

        this.setState(
            {
                summary: markdown,
                card: this.card
            },
            () => {
                if (showDefaultCard) {
                    this.setDefaultCard(this.card);
                }
                this.updateCard();
            }
        );
    };

    private onCancelScheduledTask = async () => {
        await cancelScheduledMessage(this.state.messageId).then(() => {
            microsoftTeams.tasks.submitTask();
        }).catch((error) => {
            throw error;
        });
    }

    private onAuthorChanged = (event: any) => {
        let showDefaultCard = (!this.state.title && !this.state.imageLink && !this.state.videoLink && !this.state.summary && !event.target.value);
        setCardTitle(this.card, this.state.title);
        setCardImageLink(this.card, this.state.imageLink);
        setCardVideoLink(this.card, this.state.videoLink, this.state.videoImageLink);
        setCardSummary(this.card, this.state.summary);
        setCardAuthor(this.card, event.target.value);

        setActions(this.card, this.state.actions);

        this.setState(
            {
                author: event.target.value,
                card: this.card
            },
            () => {
                if (showDefaultCard) {
                    this.setDefaultCard(this.card);
                }
                this.updateCard();
            }
        );
    };

    private updateCard = () => {
        const adaptiveCard = new AdaptiveCards.AdaptiveCard();
        AdaptiveCards.AdaptiveCard.onProcessMarkdown = function (text, result) {
            result.outputHtml = markDownToHtml(text);
            result.didProcess = true;
        };

        setCardStyle(this.state.card, this.state.selectedUpdateType);
        adaptiveCard.parse(this.state.card);
        const renderedCard = adaptiveCard.render() as HTMLElement;
        const adaptiveCardContainer = document.getElementsByClassName('adaptiveCardContainer')[0];

        if (adaptiveCardContainer !== null && adaptiveCardContainer !== undefined) {
            const container = adaptiveCardContainer.firstChild;
            if (container != null) {
                container.replaceWith(renderedCard);
            } else {
                adaptiveCardContainer.appendChild(renderedCard);
            }
            // const link = this.state.btnLink;
            // adaptiveCard.onExecuteAction = function (action) { window.open(link, '_blank'); }
        }
    }
}

const newMessageWithTranslation = withTranslation()(NewMessage);
export default newMessageWithTranslation;