import { formatDate } from '../i18n';
import { getSentNotifications, getDraftNotifications, getScheduledNotifications, getAllSentNotifications} from '../apis/messageListApi';

type Notification = {
    createdDateTime: string,
    failed: number,
    id: string,
    isCompleted: boolean,
    sentDate: string,
    sendingStartedDate: string,
    sendingDuration: string,
    succeeded: number,
    throttled: number,
    title: string,
    totalMessageCount: number,
    scheduledDate: string,
    token: object
}

export const selectMessage = (message: any) => {
    return {
        type: 'MESSAGE_SELECTED',
        payload: message
    };
};

export const getMessagesList = (nextPartitionKey: string, nextRowKey: string) => async (dispatch: any) => {
    if (nextPartitionKey == undefined){
        nextPartitionKey = "";
    }
    if (nextRowKey == undefined) {
        nextRowKey = "";
    }
    const response = await getSentNotifications(nextPartitionKey, nextRowKey);
    const notificationList: Notification[] = response.data;
    notificationList.forEach(notification => {
        notification.sendingStartedDate = formatDate(notification.sendingStartedDate);
        notification.sentDate = formatDate(notification.sentDate);
    });
    dispatch({ type: 'FETCH_MESSAGES', payload: notificationList });
};

export const getAllSentNotificationsList = () => async (dispatch: any) => {
    const response = await getAllSentNotifications();
    const notificationList: Notification[] = response.data;
    notificationList.forEach(notification => {
        notification.sendingStartedDate = formatDate(notification.sendingStartedDate);
        notification.sentDate = formatDate(notification.sentDate);
    });
    dispatch({ type: 'FETCH_ALL_SENTNOTIFICATIONS', payload: notificationList });
}

export const getDraftMessagesList = () => async (dispatch: any) => {
    const response = await getDraftNotifications();
    dispatch({ type: 'FETCH_DRAFTMESSAGES', payload: response.data });
};

export const getScheduledMessagesList = () => async (dispatch: any) => {
    const response = await getScheduledNotifications();
    const notificationList: Notification[] = response.data;
    notificationList.forEach(notification => {
        notification.sendingStartedDate = formatDate(notification.sendingStartedDate);
        notification.sentDate = formatDate(notification.sentDate);
    });
    dispatch({ type: 'FETCH_SCHEDULED_MESSAGES', payload: notificationList });
};