// @ts-nocheck
import "react-datepicker/dist/react-datepicker.css";
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode } from '@fluentui/react/lib/DetailsList';
import * as microsoftTeams from "@microsoft/teams-js";
import { Button, Loader } from '@stardust-ui/react';
import {
    Chart as ChartJS, BarElement, TimeScale, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip
} from 'chart.js';
import { TFunction } from "i18next";
import * as React from 'react';
import 'chartjs-adapter-moment';
import 'chartjs-adapter-date-fns';
import { Bar, Line } from 'react-chartjs-2';
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { getAllSentNotificationsList, getMessagesList, getScheduledMessagesList } from '../../actions';
import { IMessage } from '../ScheduledMessages/scheduledMessages';
import './insightsDashboard.scss';
import SingleMessageReport from "./singleMessageReport";
import { getSentMessageViews } from "../../apis/messageListApi";
import { IColumn, ActionButton, Dropdown, Icon, IconButton, IDropdownOption, IIconProps, DefaultButton } from "@fluentui/react";
import BarChart from '../Charts/barChart';
import moment from 'moment';
import DatePicker from "react-datepicker";

ChartJS.register(
    BarElement,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Tooltip,
    Legend,
    Title,
);

interface INotification {
    id: string;
    title: string;
    sentDate: string;
    sender: string;
    status: string;
    succeeded: string;
    failed: string;
    isImportant: boolean;
    totalMessageCount: number;
    views: number;
    popularity: number;
    buttonClicks: number;
}

interface IState {
    loader: boolean;
    allNotifications: INotification[];
    pagedNotifications: any[];
    scheduledMessages: IMessage[];
    notificationsTotal: number;
    notificationsSent: number;
    notificationsImportant: number;
    notificationsScheduled: number;
    messagesTotal: number;
    messagesSent: number;
    messagesViewed: number;
    messagedFailed: number;
    latestTenNotifications: INotification[];
    pageNumber: number;
    pageToken: any[];
    columns: any[];
    singleMessageView: boolean;
    selectedItem: any;
    selectedNotifications: any;
    minDate: string;
    maxDate: string;
    startDate: Date;
    endDate: Date;
}

export interface IInsightsDashboardProps extends WithTranslation {
    getMessagesList?: any;
    getScheduledMessagesList?: any;
    allNotifications?: any;
    allMessages?: any;
    messagesList: any[];
    scheduledMessages?: any;
    getAllSentNotificationsList?: any;
}

const detailIcon: IIconProps = { iconName: 'AnalyticsView' };

class InsightsDashboard extends React.Component<IInsightsDashboardProps, IState> {
    readonly localize: TFunction;
    private selection: Selection;
    chartReference: any;

    constructor(props: IInsightsDashboardProps) {
        super(props);
        this.localize = this.props.t;
        this.state = {
            loader: true,
            allNotifications: this.props.allNotifications,
            pagedNotifications: this.props.messagesList,
            scheduledMessages: this.props.scheduledMessages,
            notificationsTotal: 0,
            notificationsSent: 0,
            notificationsImportant: 0,
            notificationsScheduled: 0,
            messagesTotal: 0,
            messagesSent: 0,
            messagesViewed: 0,
            messagedFailed: 0,
            latestTenNotifications: [],
            pageNumber: 1,
            pageToken: [{ nextPartitionKey: "", nextRowKey: "" }],
            columns: [],
            singleMessageView: false,
            selectedItem: {},
            selectedNotifications: {},
            minDate: "",
            maxDate: "",
            startDate: moment().subtract(7, "days").toDate(),
            endDate: moment(new Date()).toDate(),
        }

        this.chartReference = React.createRef();

        this.selection = new Selection({
            onSelectionChanged: async () => {
                const selectionTargetted = this.selection.getSelection()[0];
                if (selectionTargetted !== undefined) {
                    //Get notifications
                    const response = await getSentMessageViews(selectionTargetted);
                    var minSelectedDate = response.data.minimumDate ? response.data.minimumDate : moment(new Date()).format("YYYY-MM-DD");
                    minSelectedDate = moment(minSelectedDate).subtract(1, "days").format("YYYY-MM-DD");
                    this.setState({
                        selectedItem: selectionTargetted,
                        selectedNotifications: response.data.messages,
                        minDate: minSelectedDate,
                        maxDate: moment(minSelectedDate).add(8, "days").format("YYYY-MM-DD"),
                        startDate: moment(minSelectedDate).toDate(),
                        endDate: moment(minSelectedDate).add(8, "days").toDate(),
                    });
                    this.updateChart();
                }
            },
        });
    }


    public async componentDidMount() {
        microsoftTeams.initialize();
        this.props.getAllSentNotificationsList().then(() => {
            this.props.getMessagesList();
        });

        const columns: IColumn[] = [
            {
                key: 'column1',
                name: 'Sent Date',
                fieldName: 'sentDate',
                isSorted: true,
                isResizable: true,
                minWidth: 100,
                maxWidth: 150,
                data: 'string',
                onColumnClick: this.onColumnClick,
                onRender: (item: INotification) => (
                    <span>{item.sentDate}</span>
                ),
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Title',
                fieldName: 'title',
                minWidth: 100,
                maxWidth: 350,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this.onColumnClick,
                data: 'string',
                onRender: (item: INotification) => {
                    return <span>{item.title}</span>;
                },
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Sender',
                fieldName: 'sender',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this.onColumnClick,
                data: 'string',
                onRender: (item: INotification) => {
                    return <span>{item.sender}</span>;
                },
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Status',
                fieldName: 'status',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                onColumnClick: this.onColumnClick,
                data: 'string',
                onRender: (item: INotification) => {
                    return <span>{item.status}</span>;
                },
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Total Recipients',
                fieldName: 'totalRecipients',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'number',
                onColumnClick: this.onColumnClick,
                onRender: (item: INotification) => {
                    return <span>{item.totalMessageCount}</span>;
                },
                isPadded: true,
            },
            {
                key: 'column6',
                name: 'Succeeded',
                fieldName: 'succeeded',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'number',
                onColumnClick: this.onColumnClick,
                onRender: (item: INotification) => {
                    return <span>{item.succeeded}</span>;
                },
                isPadded: true,
            },
            {
                key: 'column7',
                name: 'Failed',
                fieldName: 'failed',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'number',
                onColumnClick: this.onColumnClick,
                onRender: (item: INotification) => {
                    return <span>{item.failed}</span>;
                },
                isPadded: true,
            },
            {
                key: 'column8',
                name: 'Is Important',
                fieldName: 'isImportant',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'string',
                onColumnClick: this.onColumnClick,
                onRender: (item: INotification) => {
                    return <span>{item.isImportant.toString()}</span>;
                },
                isPadded: true,
            }
        ];

        this.setState({ columns: columns });
    }

    public componentWillReceiveProps(nextProps: any) {
        if (this.props !== nextProps) {

            let importantMessages = [];
            let deliveredNotifications = [];
            let totalMessagesViewed = [];
            let totalMessagesSent = [];
            let totalMessagesFailed = [];

            //nextProps.allNotifications.forEach((element: any) => {
            //    if (element.isImportant === true) {
            //        importantMessages.push(element);
            //    }
            //    if (element.status === "Sent") {
            //        deliveredNotifications.push(element);
            //    }
            //});

            //nextProps.allMessages.forEach((element: any) => {
            //    if (element.isViewed) {
            //        totalMessagesViewed.push(element);
            //    }
            //    if (element.status === "Succeeded") {
            //        totalMessagesSent.push(element);
            //    }
            //    if (element.status === "Failed") {
            //        totalMessagesFailed.push(element);
            //    }
            //})

            let latestTenNotifications = [...nextProps.allNotifications];


            this.setState({
                allNotifications: nextProps.allNotifications as INotification[],
                pagedNotifications: nextProps.messagesList as INotification[],
                scheduledMessages: nextProps.scheduledMessages,
                notificationsTotal: nextProps.allNotifications.length,
                notificationsScheduled: nextProps.scheduledMessages.length,
                notificationsImportant: importantMessages.length,
                notificationsSent: deliveredNotifications.length,
                messagesTotal: nextProps.allMessages.length,
                messagesViewed: totalMessagesViewed.length,
                messagesSent: totalMessagesSent.length,
                messagedFailed: totalMessagesFailed.length,
                latestTenNotifications: latestTenNotifications,
                selectedItem: nextProps.messagesList[0],
                loader: false
            });

            if (this.state.pagedNotifications[0] !== undefined) {
                this.handleTokenCallback(nextProps.messagesList[0].token);
            }
        }
    }

    public handleTokenCallback = (tokenData: any) => {
        if (tokenData !== null) {
            let nextPartitionKey = tokenData.nextPartitionKey;
            let nextRowKey = tokenData.nextRowKey;
            if (!this.state.pageToken.find(x => x.nextPartitionKey == nextPartitionKey && x.nextRowKey == nextRowKey)) {
                this.setState({
                    pageToken: [...this.state.pageToken, { nextPartitionKey: nextPartitionKey, nextRowKey: nextRowKey }]
                });
            }
        }
    }

    public updateChart() {
        //const chart = this.chartReference.current.chartInstance;
        //chart.update();
    }

    public handleStartDateChange(date: any) {
        this.setState({
            minDate: date,
            startDate: date,
        })
        this.updateChart();
    }

    public handleEndDateChange(date: any) {
        this.setState({
            maxDate: date,
            endDate: date
        })
        this.updateChart();
    }

    //public handleSelect(ranges: any) {
    //    console.log(ranges);
    //    // {
    //    //   selection: {
    //    //     startDate: [native Date Object],
    //    //     endDate: [native Date Object],
    //    //   }
    //    // }
    //}

    public render(): JSX.Element {

        const selectionRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }

        const optionsLatest = {
            indexAxis: 'y' as const,
            elements: {
                bar: {
                    borderWidth: 2,
                },
            },
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'top' as const,
                },
                title: {
                    display: true,
                    text: 'Latest Notifications',
                },
            },
            //scales: {
            //    x: {
            //        ticks: {
            //            format: {
            //                style: 'percent'
            //            }
            //        }
            //    }
            //},
        };

        //const dataLatest = {
        //    labels: this.state.latestTenNotifications.map((e) => e.title),
        //    datasets: [
        //        {
        //            label: 'Sent',
        //            barThickness: 6,
        //            data: this.state.latestTenNotifications.map((e) => e.totalMessageCount),
        //            borderColor: '#02a5f2',
        //            backgroundColor: '#02a5f2',
        //        },
        //        {
        //            label: 'Views',
        //            barThickness: 6,
        //            data: this.state.latestTenNotifications.map((e) => e.views),
        //            borderColor: '#8fce00',
        //            backgroundColor: '#8fce00',
        //        },
        //        {
        //            label: 'Clicks',
        //            barThickness: 6,
        //            data: this.state.latestTenNotifications.map((e) => e.buttonClicks),
        //            borderColor: '#F39800',
        //            backgroundColor: '#F39800',
        //        },

        //    ],
        //};

        //const optionsSingle = {
        //    maintainAspectRatio: false,
        //    plugins: {
        //        legend: {
        //            position: 'top' as const,
        //        },
        //        title: {
        //            display: true,
        //            text: `Activity over time`,
        //        },
        //    },
        //};

        const options = {
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: `Views over time`,
                },
            },
            scales: {
                y: {
                    beginAtZero: true,
                    suggestedMin: 10,
                    suggestedMax: 10,
                    ticks: {
                        stepSize: 1,
                    }
                },
                x: {
                    type: 'time',
                    time: {
                        unit: 'day'
                    },
                    min: this.state.minDate,
                    max: this.state.maxDate,
                },
            },
        };

        let labels = [`${this.state.selectedItem?.title}`]

        const dataSingle = {
            labels,
            datasets: [
                {
                    label: 'Total',
                    data: [this.state.selectedItem?.totalMessageCount],
                    borderColor: '#02a5f2',
                    backgroundColor: '#02a5f2',
                },
                {
                    label: 'Views',
                    data: [this.state.selectedItem?.views],
                    borderColor: 'rgba(255, 99, 132, 1)',
                    backgroundColor: 'rgba(255, 99, 132, 1)',
                },
                {
                    label: 'Button Clicks',
                    data: [this.state.selectedItem?.buttonClicks],
                    borderColor: '#92c353',
                    backgroundColor: '#92c353',
                },
            ],
        };

        const data = {
            datasets: [
                {
                    label: 'View Rate',
                    data: this.state.selectedNotifications,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
        };

        if (this.state.loader) {
            return (
                <div className="calendar-loader">
                    <Loader />
                </div>
            );
        } else {
            return (
                <div className="insights-container">
                    {!this.state.singleMessageView && (
                        <>

                            <div className="insights-charts">
                                <div className="chart">
                                    <BarChart
                                        latestTenNotifications={this.state.latestTenNotifications}
                                    />
                                </div>
                                <div className="chart">
                                    <div style={{ display: "flex", marginTop: "10px", float: "right" }} >
                                        <label htmlFor="startDatePicker">Start Date
                                            <DatePicker
                                                id="startDatePicker"
                                                onChange={(date) => this.handleStartDateChange(date)}
                                                selected={this.state.startDate}
                                                selectsStart
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                            />
                                        </label>

                                        <label htmlFor="endDatePicker">End Date
                                            <DatePicker
                                                id="endDatePicker"
                                                onChange={(date) => this.handleEndDateChange(date)}
                                                selected={this.state.endDate}
                                                selectsEnd
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                minDate={this.state.startDate}
                                            />
                                        </label>

                                    </div>
                                    <Line
                                        ref={this.chartReference}
                                        options={this.state.selectedNotifications ? options : {}}
                                        data={this.state.selectedNotifications ? data : { datasets: [] }}
                                    />

                                </div>
                            </div>
                            <div className="insights-message-list">
                                {this.state.pagedNotifications.length > 0 &&
                                    <>
                                        <DetailsList
                                            items={this.state.pagedNotifications}
                                            columns={this.state.columns}
                                            selectionMode={SelectionMode.single}
                                            setKey="set"
                                            selection={this.selection}
                                            selectionPreservedOnEmptyClick={true}
                                            layoutMode={DetailsListLayoutMode.justified}
                                            isHeaderVisible={true}
                                            onItemInvoked={this.onItemInvoked}
                                        /*                                        onRenderItemColumn={this._onRenderItemColumn}*/
                                        />
                                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between" }}>
                                            <Button disabled={this.state.pageNumber === 1} style={{ margin: "2rem" }} onClick={this.onPreviousPage} primary content={"Previous Page"} color="accent" />
                                            <span style={{ marginTop: "2.5rem", fontWeight: "bold" }} >Page {this.state.pageNumber}</span>
                                            <Button disabled={!this.state.pageToken[this.state.pageNumber]} style={{ margin: "2rem" }} onClick={this.onNextPage} primary content={"Next Page"} color="accent" />
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    )}
                    {this.state.singleMessageView && (
                        <SingleMessageReport notification={this.state.selectedItem} goBack={this.goBack} />
                    )}
                </div>
            )
        }
    }

    public goBack = () => {
        this.setState({ singleMessageView: false })
    }

    public onNextPage = () => {
        let currentPage = this.state.pageNumber;
        let token = this.state.pageToken[currentPage];
        let nextPartitionKey = token.nextPartitionKey;
        let nextRowKey = token.nextRowKey;
        this.props.getMessagesList(nextPartitionKey, nextRowKey).then(() => {
            this.setState({
                pageNumber: currentPage + 1
            });
        });
    }
    public onPreviousPage = () => {
        let currentPage = this.state.pageNumber - 1;
        let token = this.state.pageToken[currentPage - 1];
        let nextPartitionKey = token.nextPartitionKey;
        let nextRowKey = token.nextRowKey;
        this.props.getMessagesList(nextPartitionKey, nextRowKey).then(() => {
            this.setState({
                pageNumber: currentPage
            });
        });
    }

    public onItemInvoked = async (item?: any, index?: number, ev?: Event) => {
        this.setState({
            selectedItem: item,
            singleMessageView: true
        })
    }

    //public _onRenderItemColumn(item: any, index: number, column: IColumn): JSX.Element {
    //    if (column.fieldName === 'column10') {
    //        return <IconButton style={{ color: "black" }} iconProps={detailIcon} ></IconButton>;
    //    }
    //    return item[column.fieldName];
    //}

    private onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, pagedNotifications } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });

        const newItems = this.copyAndSort(pagedNotifications, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            pagedNotifications: newItems,
        });
    };

    private copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }
}


const mapStateToProps = (state: any) => {
    return { allNotifications: state.allNotificationMessages, scheduledMessages: state.scheduledMessages, allMessages: state.allMessages, messagesList: state.messagesList };
}

const insightsDashboardWithTranslation = withTranslation()(InsightsDashboard);
export default connect(mapStateToProps, { getScheduledMessagesList, getAllSentNotificationsList, getMessagesList })(insightsDashboardWithTranslation);