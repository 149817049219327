import { TFunction } from "i18next";

export const getInitAdaptiveCard = (t: TFunction) => {
    const titleTextAsString = t("TitleText");

    return (
        {
            "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
            "type": "AdaptiveCard",
            "version": "1.2",
            "backgroundImage": {
                "url": "/Content/colors/FFFFFF.png"
            },
            "body": [
                {},
                {
                    "type": "Container",
                    "spacing": "none",
                    "items": [
                        {
                            "type": "TextBlock",
                            "weight": "Bolder",
                            "text": titleTextAsString,
                            "size": "ExtraLarge",
                            "wrap": true
                        },
                        {
                            "type": "Image",
                            "spacing": "Default",
                            "url": "",
                            "size": "Stretch",
                            "altText": ""
                        },
                        {
                            "type": "Image",
                            "spacing": "Default",
                            "url": "",
                            "size": "Stretch",
                            "width": "400px",
                            "altText": ""
                        },
                        {
                            "type": "TextBlock",
                            "text": "",
                            "wrap": true
                        },
                        {
                            "type": "TextBlock",
                            "wrap": true,
                            "size": "Small",
                            "weight": "Lighter",
                            "text": ""
                        }
                    ],
                },

            ],
        }
    );
}


const isNew = (type: string): boolean => {
    return ["00", "01", "02", "03", "04", "05", "06", "07", "08"].includes(type)
}
const setHeader = (card: any, type: string, headerImageUrl: string, headerText?: string) => {
    card.body[0] = {}
    if (isNew(type)) {
        return {
            "type": "Container",
            "spacing": "Default",
            "items": [
                {
                    "type": "Image",
                    "spacing": "none",
                    "url": headerImageUrl,
                    "size": "Stretch",

                    "altText": ""
                }
            ],
        }
    } else if (headerImageUrl && headerText) {
        return {
            "type": "Container",
            "items": [
                {
                    "type": "ColumnSet",
                    "columns": [
                        {
                            "type": "Column",
                            "width": "auto",
                            "items": [
                                {
                                    "type": "Image",
                                    "size": "Small",
                                    "url": headerImageUrl
                                }
                            ]
                        },
                        {
                            "type": "Column",
                            "items": [
                                {
                                    "type": "TextBlock",
                                    "size": "Medium",
                                    "weight": "Bolder",
                                    "color": "Dark",
                                    "text": headerText,
                                    "wrap": true,
                                    "spacing": "Small"
                                }
                            ],
                            "verticalContentAlignment": "Center",
                            "width": "stretch"
                        }
                    ]
                }
            ],
        }
    }
}

export const getCardTitle = (card: any) => {
    return card.body[1].items[0].text;
}

export const setCardTitle = (card: any, title: string) => {
    card.body[1].items[0].text = title;
}

export const getCardImageLink = (card: any) => {
    return card.body[1].items[1].url;
}

export const setCardImageLink = (card: any, imageLink?: string) => {
    card.body[1].items[1].url = imageLink;
}

export const getCardVideoLink = (card: any) => {
    return card.body[1].items[2].url;
}

export const setCardVideoLink = (card: any, videoLink?: string, videoImageLink?: string) => {
    if (videoLink !== "") {
        if (videoImageLink !== "") {
            card.body[1].items[2].url = videoImageLink;
        } else {
            card.body[1].items[2].url = "/Content/Video_Thumbnail.png";
        }
    } else {
        card.body[1].items[2].url = "";
    }
}

export const setCardVideoImageLink = (card: any, imageLink?: string) => {
    if (imageLink) {
        card.body[1].items[2].url = imageLink;
    }
}

export const getCardSummary = (card: any) => {
    return card.body[1].items[3].text;
}

export const setCardSummary = (card: any, summary?: string) => {
    card.body[1].items[3].text = summary;
}

export const getCardAuthor = (card: any) => {
    return card.body[1].items[4].text;
}

export const setCardAuthor = (card: any, author?: string) => {
    card.body[1].items[4].text = author;
}

export const getCardBtnTitle = (card: any) => {
    return card.actions[1].title;
}

export const getCardBtnLink = (card: any) => {
    return card.actions[1].url;
}

export const setCardStyle = (card: any, updateType?: string) => {
    switch (updateType) {
        case "00": {
            card.backgroundImage.url = null;
            card.body[0] = setHeader(card, updateType, '/Content/TTEC Banner.png');
            break;
        }
        case "01": {
            card.backgroundImage.url = null;
            card.body[0] = setHeader(card, updateType, '/Content/TTEC Banner-1.png');
            break;
        }
        case "02": {
            card.backgroundImage.url = null;
            card.body[0] = setHeader(card, updateType, '/Content/TTEC Banner-2.png');
            break;
        }
        case "03": {
            card.backgroundImage.url = null;
            card.body[0] = setHeader(card, updateType, '/Content/TTEC Banner-3.png');
            break;
        }
        case "04": {
            card.backgroundImage.url = null;
            card.body[0] = setHeader(card, updateType, '/Content/TTEC Banner-4.png');
            break;
        }
        case "05": {
            card.backgroundImage.url = null;
            card.body[0] = setHeader(card, updateType, '/Content/TTEC Banner-5.png');
            break;
        }
        case "06": {
            card.backgroundImage.url = null;
            card.body[0] = setHeader(card, updateType, '/Content/TTEC Banner-6.png');
            break;
        }
        case "07": {
            card.backgroundImage.url = null;
            card.body[0] = setHeader(card, updateType, '/Content/TTEC Banner-7.png');
            break;
        }
        case "08": {
            card.backgroundImage.url = null;
            card.body[0] = setHeader(card, updateType, '/Content/TTEC Banner-8.png');
            break;
        }
        default: {
            card.backgroundImage.url = null;
            card.body[0] = setHeader(card, '00', '/Content/TTEC Banner.png');
            break;
        }
    }
}

export const setActions = (card: any, actions: any[]) => {
    let urlsAndEvents: any[] = actions ? [...actions] : []


    if (urlsAndEvents.length !== 0) {

        card.actions = []
        urlsAndEvents.forEach(x => card.actions.push({
            "type": "Action.OpenUrl",
            "title": x.title === "" ? "Button" : x.title,
            "url": x.link
        }))
    } else {
        delete card.actions;
    }

}
