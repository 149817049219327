import React, { FC } from 'react'
import { Checkbox } from 'msteams-ui-components-react';
import { Icon } from '@stardust-ui/react';


import './checkBoxWithTooltip.scss'

interface ICheckBoxWithTooltipProps {
    checked: boolean,
    onChecked: any,
    tooltip: string,
    label: string,
    style? : any
}


const CheckBoxWithTooltip: FC<ICheckBoxWithTooltipProps> = (props: ICheckBoxWithTooltipProps) => {
    return (
        <div className="checkBoxWithTooltip">
            <Checkbox style={props.style} value={1} label={props.label}
                checked={props.checked}
                onChecked={(c, v) => props.onChecked(c)} />
            <Icon name="info" title={props.tooltip} xSpacing="after" className="succeeded" contextMenu="dsd" outline />
        </div>)
}

export default CheckBoxWithTooltip;