import React, { useRef, useState } from 'react'
import { Button, Loader, Dropdown, Text, IconProps } from '@stardust-ui/react';
import { uploadFile } from '../../../apis/messageListApi';
import { AxiosResponse, AxiosError } from 'axios'

import './fileUploader.scss'

interface IFileUploaderProps {
    url: string,
    onSuccess: (response: AxiosResponse) => void,
    onError?: (response?: AxiosResponse) => void,
    buttonIcon: any,
    buttonText: string,
    acceptedFileTypes: string,
    disabled?: boolean,
}


const FileUploader: React.FC<IFileUploaderProps> = (props: IFileUploaderProps) => {
    const downloadIcon: IconProps = { name: 'download', size: "medium" };
    const imageAttachmentButtonRef: React.RefObject<any> = useRef();
    const [ fileUploadInProgress, setfileUploadInProgress ] = useState(false);
    const [ fileUploadError, setFileUploadError ] = useState<string | undefined>(undefined);

    const uploadFileMiddleware = async (event: any) => {
        setFileUploadError(undefined)
        if (event?.target.value.length == 0) {
            return;
        }
    
        setfileUploadInProgress(true);
        event?.preventDefault();
        let file = event.target.files[0];
        const formData = new FormData();
        formData.append("formFile", file);
        formData.append("fileName", file);
    
        try {
            let response = await uploadFile(props.url, formData);
            if (response.status >= 200) {
                setfileUploadInProgress(false);
                props.onSuccess && props.onSuccess(response);
            }
        } catch (e) {
            setfileUploadInProgress(false);
            setFileUploadError("Failed to upload file")
            props.onError && props.onError(e as AxiosResponse);
        }
    
    }

    return (
        <div className="fileUploader">
            <input type="file" ref={imageAttachmentButtonRef} name="file" accept={props.acceptedFileTypes} onChange={uploadFileMiddleware} onClick={ (event: any) => event.target.value = null} style={{ display: "none" }} />
            <Button onClick={(event: any) => { event?.preventDefault(); imageAttachmentButtonRef?.current?.click(); }} disabled={props.disabled} icon={props.buttonIcon} primary content={props.buttonText} color="accent" />
            {fileUploadInProgress && <Loader id="uploadingLoader" size="smallest" label={"Uploading..."} labelPosition="end" />}
            {fileUploadError && (<p className="fileUplaoder__error">Failed to upload file</p>)}
        </div>
    )
}

export default FileUploader;