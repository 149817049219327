import {
    ArcElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip
} from 'chart.js';

import * as React from 'react';
import { Bar } from "react-chartjs-2";

type Props = {
    latestTenNotifications: INotification[];
};

interface INotification {
    id: string;
    title: string;
    sentDate: string;
    sender: string;
    status: string;
    succeeded: string;
    failed: string;
    isImportant: boolean;
    totalMessageCount: number;
    views: number;
    popularity: number;
    buttonClicks: number;
}

const optionsLatest = {
    indexAxis: 'y' as const,
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Latest Notifications',
        },
    },
};



ChartJS.register(
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
    Tooltip,
    Legend,
    Title,
);

const BarChart: React.FC<Props> = ({
    latestTenNotifications
}) => {
    const dataLatest = {
        labels: latestTenNotifications.map((e) => e.title),
        datasets: [
            {
                label: 'Sent',
                barThickness: 6,
                data: latestTenNotifications.map((e) => e.totalMessageCount),
                borderColor: '#02a5f2',
                backgroundColor: '#02a5f2',
            },
            {
                label: 'Views',
                barThickness: 6,
                data: latestTenNotifications.map((e) => e.views),
                borderColor: '#8fce00',
                backgroundColor: '#8fce00',
            },
            {
                label: 'Clicks',
                barThickness: 6,
                data: latestTenNotifications.map((e) => e.buttonClicks),
                borderColor: '#F39800',
                backgroundColor: '#F39800',
            },

        ],
    };
    return (
            <Bar
                options={optionsLatest}
                data={dataLatest}
            />
    );
};

export default BarChart;