import * as React from 'react';
import { withTranslation, WithTranslation } from "react-i18next";
import './viewUpdateTaskModule.scss';
import { RouteComponentProps } from 'react-router-dom';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { Icon, Loader, List, Image, Button, IconProps } from '@stardust-ui/react';
import * as microsoftTeams from "@microsoft/teams-js";
import { formatDate, formatDuration, formatNumber } from '../../i18n';
import { TFunction } from "i18next";
import moment from 'moment';


export interface IListItem {
    header: string,
    media: JSX.Element,
}

export interface IAction {
    title: string,
    link: string
}

export interface IUpdateState {
    updates: any,
    updateList: IUpdateDetails[]
}

export interface IUpdateDetails {
    Date: Date,
    Message: string,
}

interface viewStatusTaskModuleProps extends RouteComponentProps, WithTranslation { }

class viewUpdateTaskModule extends React.Component<viewStatusTaskModuleProps, IUpdateState> {
    readonly localize: TFunction;
    private initMessage = {
        id: "",
        title: "",
        actions: [],
        updates: "",
    };

    private card: any;

    constructor(props: viewStatusTaskModuleProps) {
        super(props);
        initializeIcons();

        this.localize = this.props.t;
        let query = this.GetQueryParameters();
        var data = JSON.parse(query["updates"]);

        this.state = {
            updates: query["updates"],
            updateList: JSON.parse(query["updates"])
        };

        console.log(this.state.updateList);
    }

    private GetQueryParameters = () => {
        let queryParams: any = {};
        window.location.search.substr(1).split("&").forEach(function (item) {
            let s = item.split("="),
                k = s[0],
                v = s[1] && decodeURIComponent(s[1]);
            queryParams[k] = v;
        });
        return queryParams;
    }



    public componentDidMount() {
        //let params = this.props.match.params;
        microsoftTeams.initialize();



       
    }

    public render(): JSX.Element {
        return (
            <div className="updateModule">
                <table className="updateTable">

                    <tr>
                        <th style={{width: "250px"}}>Date</th>
                        <th>Update</th>
                    </tr>
                    {this.state.updateList.map((data, i) => {
                        // Return the element. Also pass key     
                        return (<tr><td>{moment(new Date(data.Date!)).format("MM/DD/YYYY hh:mm A z")}</td><td style={{whiteSpace: "pre-line"}}>{data.Message}</td></tr>)
                    })}
                </table>      
            </div>
        );
    }

    private onClose = () => {
        microsoftTeams.tasks.submitTask();
    }
}

const viewUpdateTaskModuleWithTranslation = withTranslation()(viewUpdateTaskModule);
export default viewUpdateTaskModuleWithTranslation;