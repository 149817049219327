import * as React from 'react';
import { withTranslation, WithTranslation } from "react-i18next";
import './videoPlayerTaskModule.scss';
import { RouteComponentProps } from 'react-router-dom';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { Icon, Loader, List, Image, Button, IconProps } from '@stardust-ui/react';
import * as microsoftTeams from "@microsoft/teams-js";
import moment from 'moment';
import {
    getInitAdaptiveCard, setCardTitle, setCardImageLink, setCardSummary,
    setCardAuthor, setCardStyle, setActions
} from '../AdaptiveCard/adaptiveCard';
import { ImageUtil } from '../../utility/imageutility';
import { formatDate, formatDuration, formatNumber } from '../../i18n';
import { TFunction } from "i18next";
import { markDownToHtml } from '../../helpers/formattingHelper';


export interface IListItem {
    header: string,
    media: JSX.Element,
}

export interface IAction {
    title: string,
    link: string
}

export interface IVideoState {
    videoUrl?: string;
    videoType?: string
}

interface videoPlayerTaskModuleProps extends RouteComponentProps, WithTranslation { }

class videoPlayerTaskModule extends React.Component<videoPlayerTaskModuleProps, IVideoState> {
    readonly localize: TFunction;
    private initMessage = {
        id: "",
        title: "",
        actions: [],
    };

    private card: any;

    constructor(props: videoPlayerTaskModuleProps) {
        super(props);
        initializeIcons();

        this.localize = this.props.t;

        /* this.card = getInitAdaptiveCard(this.props.t);*/

        //this.state = {
        //    videoUrl: "",
        //};
        let query = this.GetQueryParameters();

        this.state = {
            videoUrl: query["videoUrl"],
            videoType: query["videoType"],
        };

        console.log(window.location.search);
        console.log(this.state.videoUrl);
        console.log(this.state.videoType);
        //let params = this.props.match.params;
        //if ('id' in params) {
        //    let videoId = params['id'];
        //    let type = params['type'];
        //    console.log(decodeURIComponent(videoId));
        //    console.log(decodeURIComponent(type));
        //    this.state = {
        //        videoUrl: decodeURIComponent(videoId),
        //        videoType: decodeURIComponent(type),
        //    };
        //}
    }

    private GetQueryParameters = () => {
        let queryParams: any = {};
        window.location.search.substr(1).split("&").forEach(function (item) {
            let s = item.split("="),
                k = s[0],
                v = s[1] && decodeURIComponent(s[1]);
            queryParams[k] = v;
        });
        return queryParams;
    }


    public componentDidMount() {
        //let params = this.props.match.params;
        microsoftTeams.initialize();
        microsoftTeams.getContext((context) => {
            //this.setState({
            //    teamId: context.teamId,
            //});
        });


    }

    public render(): JSX.Element {
        return (
            <div className="videoModule">
                <div className="formContainer">
                    {(this.state.videoType == "youtube" || this.state.videoType == "vimeo") && <iframe id="player" allowFullScreen
                        src={this.state.videoUrl} style={{ margin: 0, height: "100%", width: "100%" }}
                        frameBorder="0"></iframe>}
                    {!(this.state.videoType == "youtube" || this.state.videoType == "vimeo") &&
                        <video width="100%" height="100%" controls>
                            <source src={this.state.videoUrl} type={this.state.videoType} />
                            Your browser does not support the video tag.
                        </video>}
                </div>
            </div>
        );
    }

    private onClose = () => {
        microsoftTeams.tasks.submitTask();
    }
}

const videoPlayerTaskModuleWithTranslation = withTranslation()(videoPlayerTaskModule);
export default videoPlayerTaskModuleWithTranslation;