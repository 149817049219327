import React, { PropsWithChildren, useEffect, Ref, useRef, useCallback, useMemo, useState } from 'react'

import { Icon } from '@fluentui/react/lib/Icon';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
// import { withHistory } from 'slate-history'
// import { Loader, Button, Text, List, Image } from '@stardust-ui/react';
// //import MarkdownSerializer from 'slate-mdast-serializer'
// import gfm from "remark-gfm";
// import stringify from "remark-stringify";
// import frontmatter from "remark-frontmatter";
// import { remarkToSlate, slateToRemark } from "remark-slate-transformer";
// import { serialize } from 'remark-slate';
// import unified from 'unified';
// import markdown from 'remark-parse';
// import slate from 'remark-slate';
import './richEditor.scss';

import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { Provider as LegacyProvider, ProviderConsumer, ThemePrepared, themes } from '@stardust-ui/react';
import 'draft-js/dist/Draft.css';



import { Toolbar, Button as DefButton } from './toolbar'


interface IMyEditorProps {
  onSummaryChanged: (value: any) => void;
  placeholder: string;
  initialValue: string | undefined;
  draftIsMount: boolean;
}

class MyEditor extends React.Component<IMyEditorProps, { editorState: EditorState, initialValue: string, draftIsMount: boolean, onSummaryChanged: (value: any) => void }> {
  onChange: (editorState: any) => void;
  constructor(props: IMyEditorProps) {
    super(props);
    initializeIcons()
    const markdownString = props.initialValue;
    const rawData = markdownToDraft(markdownString || "", { preserveNewlines: true });
    const contentState = convertFromRaw(rawData);

    this.state = { editorState: EditorState.createWithContent(contentState), initialValue: props.initialValue || "", draftIsMount: props.draftIsMount, onSummaryChanged: props.onSummaryChanged };
    this.onChange = editorState => { this.setState({ editorState }); props.onSummaryChanged(this.toMD(editorState)); };
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.toggleInlineStyle = this.toggleInlineStyle.bind(this);
    this.toggleBlock = this.toggleBlock.bind(this);
    this.toMD = this.toMD.bind(this)
  }

  static getDerivedStateFromProps(newProps: any, state: any) {
    if (newProps.draftIsMount != state.draftIsMount) {
      const markdownString = newProps.initialValue;
      const rawData = markdownToDraft(markdownString || "");
      const contentState = convertFromRaw(rawData);

      return { editorState: EditorState.createWithContent(contentState), draftIsMount: true }
    }
  }

  toggleInlineStyle(e: Event, inlineStyle: string) {
    e.preventDefault();
    this.state.onSummaryChanged(this.toMD(this.state.editorState));
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
  }

  toggleBlock(e: Event, blockType: string) {
    e.preventDefault();
    this.state.onSummaryChanged(this.toMD(this.state.editorState));
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  toMD(newEditorState: any) {
    const content = newEditorState.getCurrentContent();
    const rawObject = convertToRaw(content);
    const markdownString = draftToMarkdown(rawObject, { preserveNewlines: true });
    //console.log(markdownString)
    return markdownString;
  }

  handleKeyCommand(command: string, editorState: EditorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.onChange(newState);
      return 'handled';
    }

    return 'not-handled';
    }

  render() {
    return (
      <div className="richEditor">
        <div className="richEditor__toolbar">
          {/* <DefButton onMouseDown={(e: Event) => this.toggleBlock(e, "header-one")}><Icon iconName={"Header1"} /></DefButton>
          <DefButton onMouseDown={(e: Event) => this.toggleBlock(e, "header-two")}><Icon iconName={"Header2"} /></DefButton> */}
          <DefButton active={true} onMouseDown={(e: Event) => this.toggleInlineStyle(e, "BOLD")}><Icon iconName={"Bold"} /></DefButton>
          <DefButton active={true} onMouseDown={(e: Event) => this.toggleInlineStyle(e, "ITALIC")}><Icon iconName={"Italic"} /></DefButton>
          {/* <DefButton onMouseDown={(e: Event) => this.toggleInlineStyle(e, "UNDERLINE")}><Icon iconName={"Underline"} /></DefButton> */}
          {/* <DefButton onMouseDown={(e: Event) => this.toggleInlineStyle(e, "STRIKETHROUGH")}><Icon iconName={"Strikethrough"} /></DefButton> */}
          <DefButton active={true} onMouseDown={(e: Event) => this.toggleBlock(e, "unordered-list-item")}><Icon iconName={"BulletedList"} /></DefButton>
          <DefButton active={true} onMouseDown={(e: Event) => this.toggleBlock(e, "ordered-list-item")}><Icon iconName={"NumberedList"} /></DefButton>
        </div>
        <LegacyProvider.Consumer render={(theme: ThemePrepared) => {
          return (
            <div className="richEditor__inputArea" style={{ background: theme.siteVariables.colorScheme.default.backgroundActive}}>
              <Editor
                editorState={this.state.editorState}
                handleKeyCommand={this.handleKeyCommand}
                onChange={this.onChange}
                placeholder={"Summary"}
              />
            </div>
          )
        }} />
      </div>
    );
  }
}


export default MyEditor