import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from "react-i18next";
import * as AdaptiveCards from "adaptivecards";
import { Loader, Button, Text, List, Image } from '@stardust-ui/react';
import * as microsoftTeams from "@microsoft/teams-js";
import './sendConfirmationTaskModule.scss';
import { getDraftNotification, getConsentSummaries, sendDraftNotification } from '../../apis/messageListApi';
import {
    getInitAdaptiveCard, setCardTitle, setCardImageLink, setCardSummary, setCardStyle,
    setCardAuthor, setActions, setCardVideoLink
} from '../AdaptiveCard/adaptiveCard';
import { ImageUtil } from '../../utility/imageutility';
import { TFunction } from "i18next";
import { markDownToHtml } from '../../helpers/formattingHelper'


export interface IListItem {
    header: string,
    media: JSX.Element,
}

export interface IAction {
    title: string,
    link: string
}

export interface IMessage {
    id: string;
    title: string;
    acknowledgements?: number;
    reactions?: number;
    responses?: number;
    succeeded?: number;
    failed?: number;
    throttled?: number;
    sentDate?: string;
    imageLink?: string;
    videoLink?: string;
    videoImageLink?: string;
    summary?: string;
    author?: string;
    updateType?: string;
    actions: IAction[];
    eventUrl?: string;
}

export interface SendConfirmationTaskModuleProps extends RouteComponentProps, WithTranslation {
}

export interface IStatusState {
    message: IMessage;
    loader: boolean;
    teamNames: string[];
    rosterNames: string[];
    groupNames: string[];
    userNames: string[];
    allUsers: boolean;
    messageId: number;
    usersListFile?: string,
}

class SendConfirmationTaskModule extends React.Component<SendConfirmationTaskModuleProps, IStatusState> {
    readonly localize: TFunction;
    private initMessage = {
        id: "",
        title: "",
        actions: []
    };

    private card: any;

    constructor(props: SendConfirmationTaskModuleProps) {
        super(props);
        this.localize = this.props.t;
        this.card = getInitAdaptiveCard(this.localize);

        this.state = {
            message: this.initMessage,
            loader: true,
            teamNames: [],
            rosterNames: [],
            groupNames: [],
            userNames: [],
            allUsers: false,
            messageId: 0,
            usersListFile: undefined
        };
    }

    public async componentDidMount() {
        microsoftTeams.initialize();

        let params = this.props.match.params;

        if ('id' in params) {
            let id = params['id'];
            this.getItem(id).then(() => {
                getConsentSummaries(id).then((response) => {
                    this.setState({
                        teamNames: response.data.teamNames.sort(),
                        rosterNames: response.data.rosterNames.sort(),
                        groupNames: response.data.groupNames.sort(),
                        userNames: response.data.userNames.sort(),
                        allUsers: response.data.allUsers,
                        usersListFile: response.data.usersListFile,
                        messageId: id,
                    }, () => {
                        this.setState({
                            loader: false
                        }, () => {
                            var card;
                            card = this.card
                            setCardTitle(this.card, this.state.message.title);
                            setCardImageLink(this.card, this.state.message.imageLink);
                            setCardVideoLink(this.card, this.state.message.videoLink, this.state.message.videoImageLink);
                            setCardSummary(this.card, this.state.message.summary);
                            setCardAuthor(this.card, this.state.message.author);
                            setCardStyle(this.card, this.state.message.updateType);
                            setActions(this.card, this.state.message.actions)



                            let adaptiveCard = new AdaptiveCards.AdaptiveCard();
                            AdaptiveCards.AdaptiveCard.onProcessMarkdown = function (text, result) {
                                result.outputHtml = markDownToHtml(text);
                                result.didProcess = true;
                            };

                            adaptiveCard.parse(card);
                            let renderedCard = adaptiveCard.render() as HTMLElement;
                            document.getElementsByClassName('adaptiveCardContainer')[0].appendChild(renderedCard);
                            // if (this.state.message.buttonLink) {
                            //     let link = this.state.message.buttonLink;
                            //     adaptiveCard.onExecuteAction = function (action) { window.open(link, '_blank'); };
                            // }
                        });
                    });
                });
            });
        }
    }

    private getItem = async (id: number) => {
        try {
            const response = await getDraftNotification(id);
            this.setState({
                message: response.data
            });
        } catch (error) {
            return error;
        }
    }

    public render(): JSX.Element {
        if (this.state.loader) {
            return (
                <div className="Loader">
                    <Loader />
                </div>
            );
        } else {
            return (
                <div className="taskModule">
                    <div className="formContainer">
                        <div className="formContentContainer" >
                            <div className="contentField">
                                <h3>{this.localize("ConfirmToSend")}</h3>
                                <span>{this.localize("SendToRecipientsLabel")}</span>
                            </div>

                            <div className="results">
                                {this.renderAudienceSelection()}
                            </div>
                        </div>
                        <div className="adaptiveCardContainer">
                        </div>
                    </div>

                    <div className="footerContainer">
                        <div className="buttonContainer">
                            <Loader id="sendingLoader" className="hiddenLoader sendingLoader" size="smallest" label={this.localize("PreparingMessageLabel")} labelPosition="end" />
                            <Button content={this.localize("Send")} id="sendBtn" onClick={this.onSendMessage} primary />
                        </div>
                    </div>
                </div>
            );
        }
    }

    private onSendMessage = () => {
        let spanner = document.getElementsByClassName("sendingLoader");
        spanner[0].classList.remove("hiddenLoader");
        sendDraftNotification(this.state.message).then(() => {
            microsoftTeams.tasks.submitTask();
        });
    }

    private getItemList = (items: string[]) => {
        let resultedTeams: IListItem[] = [];
        if (items) {
            resultedTeams = items.map((element) => {
                const resultedTeam: IListItem = {
                    header: element,
                    media: <Image src={ImageUtil.makeInitialImage(element)} avatar />
                }
                return resultedTeam;
            });
        }
        return resultedTeams;
    }

    private renderAudienceSelection = () => {
        let recipents: JSX.Element[] = []
        if (this.state.teamNames && this.state.teamNames.length > 0) {
            recipents.push(
                <div key="teamNames"> <span className="label">{this.localize("TeamsLabel")}</span>
                    <List items={this.getItemList(this.state.teamNames)} />
                </div>
            );
        }
        if (this.state.rosterNames && this.state.rosterNames.length > 0) {
            recipents.push (
                <div key="rosterNames"> <span className="label">{this.localize("TeamsMembersLabel")}</span>
                    <List items={this.getItemList(this.state.rosterNames)} />
                </div>);
        }  
        if (this.state.groupNames && this.state.groupNames.length > 0) {
            recipents.push (
                <div key="groupNames" > <span className="label">{this.localize("GroupsMembersLabel")}</span>
                    <List items={this.getItemList(this.state.groupNames)} />
                </div>);
        }  
        if (this.state.usersListFile || this.state.userNames && this.state.userNames.length > 0 ) {
            recipents.push (
                <div key="userNames" > <span className="label">{"User(s): "}</span>
                    { this.state.usersListFile && (<a target="_blank" href={"/api/Content/getUsersAudience?fileName=" + this.state.usersListFile}>{this.state.usersListFile}</a>) }
                    <List items={this.getItemList(this.state.userNames)} />
                </div>);
        }  
        if (this.state.allUsers) {
            recipents.push (
                <div key="allUsers">
                    <span className="label">{this.localize("AllUsersLabel")}</span>
                    <div className="noteText">
                        <Text error content={this.localize("SendToAllUsersNote")} />
                    </div>
                </div>);
        } else {
            recipents.push (<div></div>);
        }

        return ( <>{recipents}</> )
    }
}

const sendConfirmationTaskModuleWithTranslation = withTranslation()(SendConfirmationTaskModule);
export default sendConfirmationTaskModuleWithTranslation;