import * as React from 'react';
import { withTranslation, WithTranslation } from "react-i18next";
import Messages from '../Messages/messages';
import DraftMessages from '../DraftMessages/draftMessages';
import './tabContainer.scss';
import ScheduledMessages from '../ScheduledMessages/scheduledMessages';
import * as microsoftTeams from "@microsoft/teams-js";
import { getBaseUrl } from '../../configVariables';
import { Accordion, Button } from '@stardust-ui/react';
import { getDraftMessagesList, getMessagesList, getScheduledMessagesList } from '../../actions';
import { connect } from 'react-redux';
import { TFunction } from "i18next";
import ExportButton from './exportButton/ExportButton';

interface ITaskInfo {
    title?: string;
    height?: number;
    width?: number;
    url?: string;
    card?: string;
    fallbackUrl?: string;
    completionBotId?: string;
}

export interface ITaskInfoProps extends WithTranslation {
    getDraftMessagesList?: any;
    getScheduledMessagesList?: any;
    getMessagesList?: any;
}

export interface ITabContainerState {
    url: string;
    pageNumber: number;
    pageToken: any[];
}

class TabContainer extends React.Component<ITaskInfoProps, ITabContainerState> {
    readonly localize: TFunction;
    constructor(props: ITaskInfoProps) {
        super(props);
        this.localize = this.props.t;
        this.state = {
            url: getBaseUrl() + "/newmessage?locale={locale}",
            pageNumber: 1,
            pageToken: [{nextPartitionKey: "", nextRowKey: ""}]
        }
        this.escFunction = this.escFunction.bind(this);
    }

    public componentDidMount() {
        microsoftTeams.initialize();
        //- Handle the Esc key
        document.addEventListener("keydown", this.escFunction, false);
    }

    public componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    public escFunction(event: any) {
        if (event.keyCode === 27 || (event.key === "Escape")) {
            microsoftTeams.tasks.submitTask();
        }
    }

    public handleTokenCallback = (tokenData: any) => {
        if (tokenData !== null) {
            let nextPartitionKey = tokenData.nextPartitionKey;
            let nextRowKey = tokenData.nextRowKey;
            if (!this.state.pageToken.find(x => x.nextPartitionKey == nextPartitionKey && x.nextRowKey == nextRowKey)) {
                this.setState({
                    pageToken: [...this.state.pageToken, { nextPartitionKey: nextPartitionKey, nextRowKey: nextRowKey }]
                });
            }
        }
    }
    private resetPage = (reset: boolean) => {
        if (reset) {
            this.setState({
                pageNumber: 1,
                pageToken: [{ nextPartitionKey: "", nextRowKey: "" }]
            })
        }
    }

    public render(): JSX.Element {
        const panels = [
            {
                title: this.localize('DraftMessagesSectionTitle'),
                content: {
                    key: 'sent',
                    content: (
                        <div className="messages">
                            <DraftMessages resetPageCallback={this.resetPage}></DraftMessages>
                        </div>
                    ),
                },
            },
            {
                title: this.localize('ScheduledMessagesSectionTitle'),
                content: {
                    key: 'scheduled',
                    content: (
                        <div className="messages">
                            <ScheduledMessages ></ScheduledMessages>
                        </div>
                    ),
                },
            },
            {
                title: this.localize('SentMessagesSectionTitle'),
                content: {
                    key: 'draft',
                    content: (
                        <>
                            <ExportButton />
                            <div className="messages">
                                <Messages parentCallback={this.handleTokenCallback}></Messages>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between" }}>
                                <Button disabled={this.state.pageNumber == 1} style={{ margin: "2rem" }} onClick={this.onPreviousPage} primary content={"Previous Page"} color="accent" />
                                <span style={{ marginTop: "2.5rem", fontWeight: "bold" }} >Page {this.state.pageNumber}</span>
                                <Button disabled={!this.state.pageToken[this.state.pageNumber]} style={{ margin: "2rem" }} onClick={this.onNextPage} primary content={"Next Page"} color="accent" />
                            </div>
                        </>
                    ),
                },
            }
        ]
        return (
            <div className="tabContainer">
                <div className="messageContainer">
                    <Accordion defaultActiveIndex={[0, 1]} panels={panels} />
                </div>
            </div>
        );
    }

    public onNextPage = () => {
        let currentPage = this.state.pageNumber;
        let token = this.state.pageToken[currentPage];
        let nextPartitionKey = token.nextPartitionKey;
        let nextRowKey = token.nextRowKey;
        this.props.getMessagesList(nextPartitionKey, nextRowKey).then(() => {
            this.setState({
                pageNumber: currentPage + 1
            });
        });
    }
    public onPreviousPage = () => {
        let currentPage = this.state.pageNumber - 1;
        let token = this.state.pageToken[currentPage - 1];
        let nextPartitionKey = token.nextPartitionKey;
        let nextRowKey = token.nextRowKey;
        this.props.getMessagesList(nextPartitionKey, nextRowKey).then(() => {
            this.setState({
                pageNumber: currentPage
            });
        });
    }

    public onNewMessage = () => {
        let taskInfo: ITaskInfo = {
            url: this.state.url,
            title: this.localize("NewMessage"),
            height: 530,
            width: 1100,
            fallbackUrl: this.state.url,
        }

        let submitHandler = (err: any, result: any) => {
            this.props.getDraftMessagesList().then(() => {
                setTimeout(this.props.getScheduledMessagesList(), 1000)
            })

        };

        microsoftTeams.tasks.startTask(taskInfo, submitHandler);
    }
}

const mapStateToProps = (state: any) => {
    return { messages: state.draftMessagesList };
}

const tabContainerWithTranslation = withTranslation()(TabContainer);
export default connect(mapStateToProps, { getDraftMessagesList, getMessagesList, getScheduledMessagesList })(tabContainerWithTranslation);