import * as microsoftTeams from "@microsoft/teams-js";
import { Button, IconProps, Menu, tabListBehavior } from "@stardust-ui/react";
import { TFunction } from "i18next";
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import * as React from 'react';
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getDraftMessagesList, getScheduledMessagesList } from "../../actions";
import { getBaseUrl } from "../../configVariables";
import CommsCalendar from "../Calendar/calendar";
import InsightsDashboard from "../Insights/insightsDashboard";
import TabContainer from '../TabContainer/tabContainer';
import './communicatorContainer.scss'

interface IHomePageState {
  showMessages: boolean;
  showCalendar: boolean;
  showInsights: boolean;
}

interface IHomePageProps extends WithTranslation{
    context: any;
    description: string;
    siteUrl: string;
    getDraftMessagesList?: any;
    getScheduledMessagesList?: any;
}

interface ITaskInfo {
  title?: string;
  height?: number;
  width?: number;
  url?: string;
  card?: string;
  fallbackUrl?: string;
  completionBotId?: string;
}

class HomePage extends React.Component<IHomePageProps, IHomePageState> {
  readonly localize: TFunction;
  private interval: any;
  constructor(props: any) {
    super(props);
    initializeIcons();
    this.localize = this.props.t;
    this.state = {
      showCalendar: false,
      showMessages: false,
      showInsights: false
    };
    this.escFunction = this.escFunction.bind(this);
  }

  public componentDidMount() {
    microsoftTeams.initialize();
    this.setState({ showMessages: true });
    document.addEventListener("keydown", this.escFunction, false);
  }

  public componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
    clearInterval(this.interval);
  }

  // private onMenuChange = (event: any) => {
  //   if (event.key === "messages") {
  //     this.setState({
  //       showMessages: true,
  //       showCalendar: false,
  //     });
  //   }
  //   if (event.key === "calendar") {
  //     this.setState({
  //       showMessages: false,
  //       showCalendar: true,
  //       showInsights: false
  //     })
  //   }
  //   if (event.key === "insights") {
  //     this.setState({
  //       showMessages: false,
  //       showCalendar: false,
  //       showInsights: true
  //     })
  //   }
  // }

  public render(): JSX.Element {

    const addIcon: IconProps = { name: "add", size: "medium" };

      const items = [
        {
          key: 'messages',
          content: 'Messages',
          onClick: () => {
            this.setState({
              showMessages: true,
              showCalendar: false,
              showInsights: false
            });
          }
        },
        {
          key: 'calendar',
          content: 'Calendar',
          onClick: () => {
            this.setState({
              showMessages: false,
              showCalendar: true,
              showInsights: false
            });
          }
        },
        {
          key: 'insights',
          content: 'Insights',
          onClick: () => {
            this.setState({
              showMessages: false,
              showCalendar: false,
              showInsights: true
            });
          }
        }
      ]
    
      return (
        <div className="cc-container">
            <div className="cc-navigation">
              <div className="cc-nav-item cc-nav-title" >
                <h3>Company Communicator</h3>
              </div>
              <div className="cc-nav-item cc-nav-menu">
                <Menu 
                  defaultActiveIndex={0}
                  items={items}
                  underlined
                  primary
                  accessibility={tabListBehavior}
                  //onItemClick={this.onMenuChange}
                  styles={{
                    borderBottom: 'none'
                  }}
                />
              </div>
              <div className="cc-nav-item cc-nav-newmessage">
                <Button icon={addIcon} content={this.localize("NewMessage")} onClick={this.onNewMessage} primary />
              </div>
            </div>
            {this.state.showCalendar && (
              <CommsCalendar />
            )}
            {this.state.showMessages && (
              <TabContainer />
            )}
            {this.state.showInsights && (
              <InsightsDashboard />
            )}
           
        </div>
      );
  }

  public onNewMessage = () => {
    let taskInfo: ITaskInfo = {
        url: getBaseUrl() + "/newmessage?locale={locale}",
        title: this.localize("NewMessage"),
        height: 530,
        width: 1000,
        fallbackUrl: getBaseUrl() + "/newmessage?locale={locale}",
    }

    let submitHandler = (err: any, result: any) => {
        this.props.getDraftMessagesList().then(() => {
            setTimeout(this.props.getScheduledMessagesList(), 1000)
        })

    };

    microsoftTeams.tasks.startTask(taskInfo, submitHandler);
  }

  private escFunction = (event: any) => {
    if (event.keyCode === 27 || (event.key === "Escape")) {
      microsoftTeams.tasks.submitTask();
    }
  }
}

const mapStateToProps = (state: any) => {
  return { messages: state.draftMessagesList };
}

const homePageWithTranslation = withTranslation()(HomePage);
export default connect(mapStateToProps, { getDraftMessagesList, getScheduledMessagesList })(homePageWithTranslation);