import axios from './axiosJWTDecorator';
import { getBaseUrl } from '../configVariables';

let baseAxiosUrl = getBaseUrl() + '/api';

export const getSentNotifications = async (nextPartitionKey: string, nextRowKey: string): Promise<any> => {
    let url = `${baseAxiosUrl}/sentnotifications?nextPartitionKey=${nextPartitionKey}&nextRowKey=${nextRowKey}`;
    return await axios.get(url);
}

export const getAllSentNotifications = async (): Promise<any> => {
    let url = baseAxiosUrl + "/sentnotifications/allnotifications";
    return await axios.get(url);
}

export const getSentMessageViews = async (item: any): Promise<any> => {
    let url = baseAxiosUrl + "/sentnotifications/viewtimes/" + item.id;
    return await axios.get(url);
}

export const getScheduledNotifications = async (): Promise<any> => {
    let url = baseAxiosUrl + "/sentnotifications/scheduled";
    return await axios.get(url);
}

export const uploadFile = async (url: string, payload: {}): Promise<any> => {
    url = baseAxiosUrl + url;
    return await axios.post(url, payload, false);
}

export const getDraftNotifications = async (): Promise<any> => {
    let url = baseAxiosUrl + "/draftnotifications";
    return await axios.get(url);
}

export const verifyGroupAccess = async (): Promise<any> => {
    let url = baseAxiosUrl + "/groupdata/verifyaccess";
    return await axios.get(url, false);
}

export const getGroups = async (id: number): Promise<any> => {
    let url = baseAxiosUrl + "/groupdata/" + id;
    return await axios.get(url);
}

export const getUsers = async (id: number): Promise<any> => {
    let url = baseAxiosUrl + "/userdata/" + id;
    return await axios.get(url);
}

export const searchGroups = async (query: string): Promise<any> => {
    let url = baseAxiosUrl + "/groupdata/search/" + query;
    return await axios.get(url);
}

export const searchUsers = async (query: string): Promise<any> => {
    let url = baseAxiosUrl + "/userdata/search/" + query;
    return await axios.get(url);
}

export const exportNotification = async(payload: {}): Promise<any> => {
    let url = baseAxiosUrl + "/exportnotification/export";
    return await axios.put(url, payload);
}

export const getSentNotification = async (id: number, insightsData?: boolean): Promise<any> => {
    let url = baseAxiosUrl + "/sentnotifications/" + id;
    if (insightsData != null) {
        url = `${baseAxiosUrl}/sentnotifications/${id}?insightsData=${insightsData}`;
    }
    return await axios.get(url);
}

export const getDraftNotification = async (id: number): Promise<any> => {
    let url = baseAxiosUrl + "/draftnotifications/" + id;
    return await axios.get(url);
}

export const deleteDraftNotification = async (id: number): Promise<any> => {
    let url = baseAxiosUrl + "/draftnotifications/" + id;
    return await axios.delete(url);
}

export const duplicateDraftNotification = async (id: number): Promise<any> => {
    let url = baseAxiosUrl + "/draftnotifications/duplicates/" + id;
    return await axios.post(url);
}

export const sendDraftNotification = async (payload: {}): Promise<any> => {
    let url = baseAxiosUrl + "/sentnotifications";
    return await axios.post(url, payload);
}

export const updateDraftNotification = async (payload: {}): Promise<any> => {
    let url = baseAxiosUrl + "/draftnotifications";
    return await axios.put(url, payload);
}

export const updateScheduledNotification = async (payload: {}): Promise<any> => {
    let url = baseAxiosUrl + "/draftnotifications/updateSchedule";
    return await axios.put(url, payload);
}

export const createDraftNotification = async (payload: {}): Promise<any> => {
    let url = baseAxiosUrl + "/draftnotifications";
    return await axios.post(url, payload);
}

export const getTeams = async (): Promise<any> => {
    let url = baseAxiosUrl + "/teamdata";
    return await axios.get(url);
}

export const getConsentSummaries = async (id: number): Promise<any> => {
    let url = baseAxiosUrl + "/draftnotifications/consentSummaries/" + id;
    return await axios.get(url);
}

export const sendPreview = async (payload: {}): Promise<any> => {
    let url = baseAxiosUrl + "/draftnotifications/previews";
    return await axios.post(url, payload);
}

export const cancelScheduledMessage = async  (id: string): Promise<any> => {
    let url = baseAxiosUrl + "/draftnotifications/cancelSchedule/" + id;;
    return await axios.post(url);
}

export const getAuthenticationConsentMetadata = async (windowLocationOriginDomain: string, login_hint: string): Promise<any> => {
    let url = `${baseAxiosUrl}/authenticationMetadata/consentUrl?windowLocationOriginDomain=${windowLocationOriginDomain}&loginhint=${login_hint}`;
    return await axios.get(url, undefined, false);
}

export const deleteUsersAudience = async (filename: string): Promise<any> => {
    let url = `${baseAxiosUrl}/Content/deleteUsersAudience?fileName=${filename}`;
    return await axios.delete(url);
}