import { ActionButton, Dropdown, Icon, IconButton, IDropdownOption, IIconProps } from "@fluentui/react";
import * as microsoftTeams from "@microsoft/teams-js";
import { Loader } from '@stardust-ui/react';
import { TFunction } from "i18next";
import moment from 'moment';
import * as React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { getMessagesList, getScheduledMessagesList } from '../../actions';
import { getBaseUrl } from '../../configVariables';
import { IMessage, ITaskInfo } from '../ScheduledMessages/scheduledMessages';
import './calendar.scss';

// http://jquense.github.io/react-big-calendar/examples/?path=/story/about-big-calendar--page for documentation on Calendar component

interface IState {
    chosenDate: Date;
    loader: boolean;
    sentEvents: Event[];
    sentMessages: IMessage[];
    scheduledEvents: Event[];
    scheduledMessages: IMessage[];
}

interface Event {
    id: string;
    allDay?: boolean | undefined;
    title?:  string;
    start?: Date | undefined;
    end?: Date | undefined;
    resource?: any;
}

export interface ICommsCalendarProps extends WithTranslation {
    getMessagesList?: any;
    getScheduledMessagesList?: any;
    sentMessages?: any;
    scheduledMessages?: any;
}

class CommsCalendar extends React.Component<ICommsCalendarProps, IState> {
    readonly localize: TFunction;
    teamsAadGroupId: string = "";
    private isOpenTaskModuleAllowed: boolean;
    constructor(props: ICommsCalendarProps) {
        super(props);
        this.localize = this.props.t;
        this.teamsAadGroupId = "";
        this.isOpenTaskModuleAllowed = true;
        this.state = {
            loader: true,
            sentEvents: [],
            sentMessages: this.props.sentMessages,
            scheduledEvents: [],
            scheduledMessages: this.props.scheduledMessages,
            chosenDate: new Date()
        }
    }

    public async componentDidMount() {
        microsoftTeams.initialize();
        this.props.getScheduledMessagesList().then(() => {
            this.props.getMessagesList();
        });
    }

    public componentWillReceiveProps(nextProps: any) {
        if (this.props !== nextProps) {

            const scheduledMessageEvents: Event[] = nextProps.scheduledMessages.map(function(v: any) {
                var date = new Date(v.scheduledDate);
                return { id: v.id, title: v.title, start: date, end: date, resource: "scheduled" }
            })

            const sentMessageEvents: Event[] = nextProps.sentMessages.map(function(v: any) {
                var date = new Date(v.sentDate);
                return { id: v.id, title: v.title, start: date, end: date, resource: "sent" }
            })

            const events = [...scheduledMessageEvents, ...sentMessageEvents];
  
            this.setState({
                loader: false,
                sentMessages: nextProps.sentMessages,
                scheduledMessages: nextProps.scheduledMessages,
                scheduledEvents: events
            });
        }
      }

    public onOpenTaskModule = (event: any, url: string, title: string, id?: string) => {
        if (this.isOpenTaskModuleAllowed) {
          this.isOpenTaskModuleAllowed = false;
          let taskInfo: ITaskInfo = {
            url: url,
            title: title,
            height: 530,
            width: 1000,
            fallbackUrl: url,
          }
    
          let submitHandler = (err: string, result: string) => {
            // If the edit button is pressed on the status task module, it will trigger the edit task module to open
            if (result === "editing") {
                this.isOpenTaskModuleAllowed = false;
                let editUrl = getBaseUrl() + "/newmessage/scheduled/" + id + "?locale={locale}";
                let taskInfo: ITaskInfo = {
                  url: editUrl,
                  title: this.localize("EditMessage"),
                  height: 530,
                  width: 1000,
                  fallbackUrl: editUrl,
                }

                let innerSubmitHandler = (err: string, result: string) => {
                    this.isOpenTaskModuleAllowed = true;
                    this.props.getScheduledMessagesList();
                    this.props.getMessagesList();
                }
                microsoftTeams.tasks.startTask(taskInfo, innerSubmitHandler)
              }

            this.isOpenTaskModuleAllowed = true;
            this.props.getScheduledMessagesList();
            this.props.getMessagesList();
          };    
          microsoftTeams.tasks.startTask(taskInfo, submitHandler);
        }
      }
    

    private handleSelectEvent = (event: any) => {
        let url = getBaseUrl() + "/viewstatus/" + event.id + "?locale={locale}";
        this.onOpenTaskModule(null, url, this.localize("ViewStatus"), event.id);
    }
    
    private handleSelectSlot = (event: any) => {        
        const today = new Date();
        const givenDate = new Date(event.start);
        if (givenDate >= today) {
            let url = getBaseUrl() + "/newmessage/?date=" + event.start + "&locale={locale}";
            this.onOpenTaskModule(null, url, this.localize("NewMessage"));
        }
    }

    public eventStyleGetter = (event: Event, start: Date, end: Date, isSelected: boolean) => {
        const scheduled: boolean = event.resource === "scheduled";
        
        const backgroundColor = scheduled ? "#dce0fa" : "#f3f3f3";
        const border = scheduled ? "1px solid #5b5fc7" : "1px solid #9c9c9c"; 
        const borderLeft = scheduled ? "4px solid #5b5fc7" : "4px solid #9c9c9c";
        const color = scheduled ? "#3d3e78" : "#575757";
        const eventClass = scheduled ? "rbc-scheduled-message" : "rbc-sent-message";

        var style = {
            backgroundColor: backgroundColor,
            border: border,
            borderLeft: borderLeft,
            color: color,
            fontWeight: 600,
            fontSize: "12px",
            zIndex: 3
        };
        return {
            style: style,
            className: eventClass
        }
    }

    public slotStyleGetter = (date: Date) => {
        const today = new Date();
         if (date > today) {
            var style = {
                cursor: "pointer"
            };
            return {
                style: style,
                className: "rbc-slot-selectable"
            }
        } else {
            return {}
        }  
    }

    public dayStyleGetter = (date: Date) => {
        const today = new Date();
        if (date > today) {
            var style = {
                cursor: "pointer"
            };
            return {
                style: style,
                className: "rbc-slot-selectable"
            }
        } else {
            return {}
        }
    }

    public onNavigate = (focusDate: any, view: any, prevOrNext: any) => {
        const max = new Date(moment().add(6, "M").format("YYYY-MM-DDTHH:mm"));
        const min = new Date(moment().subtract(6, "M").format("YYYY-MM-DDTHH:mm"));

        if (focusDate > min && focusDate < max) {
            this.setState({
                chosenDate: focusDate
            })
        }
    }

    getCustomToolbar = (toolbar: any) => {
        const mDate = toolbar.date;
        const view = toolbar.view;
        const label = toolbar.label;

        const goToBack = () => {
            let newDate;
            if (view === "month" || view === "agenda") {
              newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
            } else if (view === "week") {
              newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() - 7,
                1
              );
            } else {
              newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() - 1,
                1
              );
            }
            toolbar.onNavigate("prev", newDate);
        };

        const goToNext = () => {
            let newDate;
            if (view === "month" || view === "agenda") {
              newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
            } else if (view === "week") {
              newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() + 7,
                1
              );
            } else {
              newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() + 1,
                1
              );
            }
            toolbar.onNavigate("next", newDate);
          };

        const goToToday = () => {
            const now = new Date();
            mDate.setMonth(now.getMonth());
            mDate.setYear(now.getFullYear());
            mDate.setDate(now.getDate());
            toolbar.onNavigate("current");
        };

        const viewMenuProps: IDropdownOption[] =  [
                {
                    key: 'month',
                    text: 'Month',
                    data: { icon: 'CalendarWorkWeek' }
                },
                {
                    key: 'week',
                    text: 'Week',
                    data: { icon: 'CalendarWeek' }
                },
                {
                    key: 'day',
                    text: 'Day',
                    data: { icon: 'CalendarDay' }
                },
                {
                    key: 'agenda',
                    text: 'Agenda',
                    data: { icon: 'CalendarAgenda' }
                },
            ];
        
        const onViewChange = (event: any, item: any) => {
            toolbar.onView(item.key);
        }

        const todayIcon: IIconProps = { iconName: 'GotoToday' };
        const previousIcon: IIconProps = { iconName: 'ChevronLeft' };
        const nextIcon: IIconProps = { iconName: 'ChevronRight' };
        const iconStyles = { marginRight: '8px' };

        const onRenderTitle = (options: any): JSX.Element => {
            const option = options[0];
          
            return (
              <div style={{ display: 'flex' }}>
                {option.data && option.data.icon && (
                  <Icon style={iconStyles} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                <span>{option.text}</span>
              </div>
            );
          };

          
        const onRenderOption = (option: any): JSX.Element => {
            return (
            <div>
                {option.data && option.data.icon && (
                <Icon style={iconStyles} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                <span>{option.text}</span>
            </div>
            );
        };
    
        return (
            <div className="rbc-toolbar">
                <span className="rbc-btn-group today-btn">
                    <ActionButton iconProps={todayIcon} onClick={goToToday} text="Today" />
                </span>  
                <span className='rbc-btn-group'>
                    <IconButton iconProps={previousIcon} onClick={goToBack} title="Previous"/>
                    <IconButton iconProps={nextIcon} onClick={goToNext} title="Next"/>
                </span>
                <span className="rbc-toolbar-label">{label}</span>
                <span className="rbc-btn-group">
                    <Dropdown 
                        options={viewMenuProps}
                        defaultValue="month"
                        selectedKey={view}
                        defaultSelectedKey={viewMenuProps[0].key}
                        onChange={onViewChange}    
                        onRenderOption={onRenderOption}
                        onRenderTitle={onRenderTitle}
                    />
                </span>
            </div>
        )
    }

    getTimeGutterFormat = (date: any, culture: any, localizer: any) => {
        return localizer.format(date, 'h a', culture);
    }

    getEventTimeRangeFormat = (range: any, culture: any, localizer: any) => {
        return localizer.format(range.start, 'h:mm a', culture);
    }

    public onNewMessage = () => {
        let taskInfo: ITaskInfo = {
            url: getBaseUrl() + "/newmessage/?locale={locale}",
            title: this.localize("NewMessage"),
            height: 530,
            width: 1000,
            fallbackUrl: getBaseUrl() + "/newmessage/?locale={locale}",
        }

        let submitHandler = (err: any, result: any) => {
            this.props.getScheduledMessagesList();
        };

        microsoftTeams.tasks.startTask(taskInfo, submitHandler);
    }

    public render(): JSX.Element {
        const localizer = momentLocalizer(moment);  
                
        if (this.state.loader) {
            return (
                <div className="calendar-loader">
                     <Loader />
                </div>
            );
        } else {
            return (
                <div>
                  <Calendar
                    localizer={localizer}
                    events={this.state.scheduledEvents}
                    messages={{ event: 'Message', noEventsInRange: 'There are no messages in this range.'}}
                    onSelectEvent={this.handleSelectEvent}
                    onSelectSlot={this.handleSelectSlot}
                    selectable
                    onSelecting={() => false}
                    step={30}
                    timeslots={2}
                    style={{ height: "calc(100vh - 53px)" }}
                    eventPropGetter={this.eventStyleGetter}
                    slotPropGetter={this.slotStyleGetter}
                    dayPropGetter={this.dayStyleGetter}
                    popup={true}
                    date={this.state.chosenDate}
                    onNavigate={this.onNavigate}
                    components={{
                        toolbar: this.getCustomToolbar,
                    }}
                    formats={{
                        timeGutterFormat: this.getTimeGutterFormat,
                        eventTimeRangeFormat: this.getEventTimeRangeFormat
                    }}
                    />
                </div>
            )
        }
    }
}

const mapStateToProps = (state: any) => {    
    return { sentMessages: state.messagesList, scheduledMessages: state.scheduledMessages };
}

const commsCalendarWithTranslation = withTranslation()(CommsCalendar);
export default connect(mapStateToProps, { getMessagesList, getScheduledMessagesList })(commsCalendarWithTranslation);