
import React, { useState, useEffect } from 'react';
import { TooltipHost } from 'office-ui-fabric-react';
import { useTranslation } from 'react-i18next';
import { Accordion, Button, IconProps, Loader } from '@stardust-ui/react';
import { exportNotification } from '../../../apis/messageListApi';
import * as microsoftTeams from "@microsoft/teams-js";
import './ExportButton.scss'

const ExportButton = () => {
    const downloadIcon: IconProps = { name: 'download', size: "medium" };
    const [ sendingRequest, setSendingRequest ] = useState<boolean>(false);
    const [ errorMessage, setErrorMessage ] = useState<string | undefined>();
    const [ teamId, setTeamId ] = useState<string | undefined>(undefined);
    const [ t, i18n ] = useTranslation();

    useEffect(() => {
        microsoftTeams.getContext((context) => setTeamId(context.teamId));
    }, []);

    const sendExportRequest = async (event: any) => {
        setErrorMessage("");
        setSendingRequest(true);
        let payload = {
            teamId: teamId,
            isAll: true
        };
        await exportNotification(payload).then(() => {
            setSendingRequest(false);
        }).catch(() => {
            setErrorMessage("Something went wrong")
            setSendingRequest(false);
        });
    }

    return (
        <div className="exportButton">
            {sendingRequest && <Loader id="sendingLoader" size="smallest" label={t("ExportLabel")} labelPosition="end" />}
            { errorMessage }
            <TooltipHost content={"Export detailed result (1 year)"} calloutProps={{ gapSpace: 0 }}>
                <Button  disabled={!teamId && sendingRequest} icon={downloadIcon} content="Full Export" onClick={sendExportRequest} primary/>
            </TooltipHost>
        </div>
    )
}

export default ExportButton;