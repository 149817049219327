import * as React from 'react';
import { withTranslation, WithTranslation } from "react-i18next";
import './statusTaskModule.scss';
import { getSentNotification, exportNotification, cancelScheduledMessage } from '../../apis/messageListApi';
import { RouteComponentProps } from 'react-router-dom';
import * as AdaptiveCards from "adaptivecards";
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { TooltipHost } from 'office-ui-fabric-react';
import { Icon, Loader, List, Image, Button, IconProps } from '@stardust-ui/react';
import * as microsoftTeams from "@microsoft/teams-js";
import moment from 'moment';
import {
    getInitAdaptiveCard, setCardTitle, setCardImageLink, setCardSummary,
    setCardAuthor, setCardStyle, setActions, setCardVideoLink
} from '../AdaptiveCard/adaptiveCard';
import { ImageUtil } from '../../utility/imageutility';
import { formatDate, formatDuration, formatNumber } from '../../i18n';
import { TFunction } from "i18next";
import { markDownToHtml } from '../../helpers/formattingHelper'
import { ITaskInfo } from '../OverFlow/scheduledMessageOverflow';


export interface IListItem {
    header: string,
    media: JSX.Element,
}

export interface IAction {
    title: string,
    link: string
}

export interface IUpdateDetails {
    date: Date,
    message: string,
}

export interface IMessage {
    id: string;
    title: string;
    acknowledgements?: string;
    reactions?: string;
    responses?: string;
    succeeded?: string;
    failed?: string;
    unknown?: string;
    sentDate?: string;
    imageLink?: string;
    videoLink?: string;
    videoImageLink?: string;
    summary?: string;
    author?: string;
    updateType?: string;
    buttonLink?: string;
    buttonTitle?: string;
    teamNames?: string[];
    rosterNames?: string[];
    groupNames?: string[];
    userNames?: string[];
    allUsers?: boolean;
    sendingStartedDate?: string;
    sendingDuration?: string;
    errorMessage?: string;
    warningMessage?: string;
    canDownload?: boolean;
    sendingCompleted?: boolean;
    isScheduled?: boolean;
    scheduledDate?: string;
    sender?: string,
    creator?: string,
    usersListFile?: string;
    actions: IAction[]
}

export interface IStatusState {
    message: IMessage;
    loader: boolean;
    page: string;
    teamId?: string;
}

interface StatusTaskModuleProps extends RouteComponentProps, WithTranslation { }

class StatusTaskModule extends React.Component<StatusTaskModuleProps, IStatusState> {
    readonly localize: TFunction;
    private initMessage = {
        id: "",
        title: "",
        actions: []
    };

    private card: any;
    private incidentCard: any;

    constructor(props: StatusTaskModuleProps) {
        super(props);
        initializeIcons();

        this.localize = this.props.t;

        this.card = getInitAdaptiveCard(this.props.t);

        this.state = {
            message: this.initMessage,
            loader: true,
            page: "ViewStatus",
            teamId: ''
        };
    }

    public async componentDidMount() {
        let params = this.props.match.params;
        microsoftTeams.initialize();
        microsoftTeams.getContext((context) => {
            this.setState({
                teamId: context.teamId,
            });
        });

        if ('id' in params) {
            let id = params['id'];
            this.getItem(id).then(() => {
                this.setState({
                    loader: false
                }, () => {

                    var card;

                    setCardTitle(this.card, this.state.message.title);
                    setCardImageLink(this.card, this.state.message.imageLink);
                    setCardVideoLink(this.card, this.state.message.videoLink, this.state.message.videoImageLink);
                    setCardSummary(this.card, this.state.message.summary);
                    setCardAuthor(this.card, this.state.message.author);
                    setCardStyle(this.card, this.state.message.updateType);
                    setActions(this.card, this.state.message.actions)
                    card = this.card


                    let adaptiveCard = new AdaptiveCards.AdaptiveCard();
                    AdaptiveCards.AdaptiveCard.onProcessMarkdown = function (text, result) {
                        result.outputHtml = markDownToHtml(text);
                        result.didProcess = true;
                    };

                    adaptiveCard.parse(card);
                    let renderedCard = adaptiveCard.render() as HTMLElement;
                    document.getElementsByClassName('adaptiveCardContainer')[0].appendChild(renderedCard);
                    let link = this.state.message.buttonLink;
                    adaptiveCard.onExecuteAction = function (action) { window.open(link, '_blank'); }
                });
            });
        }
    }

    private getItem = async (id: number) => {
        try {
            const response = await getSentNotification(id);
            response.data.sendingDuration = formatDuration(response.data.sendingStartedDate, response.data.sentDate);
            response.data.sendingStartedDate = formatDate(response.data.sendingStartedDate);
            response.data.sentDate = formatDate(response.data.sentDate);
            response.data.succeeded = formatNumber(response.data.succeeded);
            response.data.failed = formatNumber(response.data.failed);
            response.data.unknown = response.data.unknown && formatNumber(response.data.unknown);
            this.setState({
                message: response.data
            });
        } catch (error) {
            return error;
        }
    }

    public render(): JSX.Element {
        if (this.state.loader) {
            return (
                <div className="Loader">
                    <Loader />
                </div>
            );
        } else {
            const downloadIcon: IconProps = { name: 'download', size: "medium" };
            if (this.state.page === "ViewStatus") {
                return (
                    <div className="taskModule">
                        <div className="formContainer">
                            <div className="formContentContainer" >
                                <div className="contentField">
                                    <h3>{this.localize("TitleText")}</h3>
                                    <span>{this.state.message.title}</span>
                                </div>
                                <div className="contentField">
                                    <h3>{this.localize("SendingStarted")}</h3>
                                    <span>{this.state.message.sendingStartedDate}</span>
                                </div>
                                {this.state.message.sendingCompleted && (
                                    <>
                                        <div className="contentField">
                                            <h3>{this.localize("Completed")}</h3>
                                            <span>{this.state.message.sentDate}</span>
                                        </div>

                                        <div className="contentField">
                                            <h3>{this.localize("Duration")}</h3>
                                            <span>{this.state.message.sendingDuration}</span>
                                        </div>
                                    </>
                                )}
                                {this.state.message.isScheduled && (
                                    <div className="contentField">
                                        <h3>Scheduled Date</h3>
                                        <span>{moment(new Date(this.state.message.scheduledDate!)).format("MM/DD/YYYY hh:mm A")}</span>
                                    </div>
                                )}

                                {this.state.message.creator && (
                                    <div className="contentField">
                                        <h3>Sent By</h3>
                                        <span>{this.state.message.creator}</span>
                                    </div>
                                )}

                                <div className="contentField">
                                    <h3>{this.localize("Results")}</h3>
                                    <label>{this.localize("Success", { "SuccessCount": this.state.message.succeeded })}</label>
                                    <br />
                                    <label>{this.localize("Failure", { "FailureCount": this.state.message.failed })}</label>
                                    <br />
                                    {this.state.message.unknown &&
                                        <>
                                            <label>{this.localize("Unknown", { "UnknownCount": this.state.message.unknown })}</label>
                                        </>
                                    }
                                </div>
                                <div className="contentField">
                                    {this.renderAudienceSelection()}
                                </div>
                                <div className="contentField">
                                    {this.renderErrorMessage()}
                                </div>
                                <div className="contentField">
                                    {this.renderWarningMessage()}
                                </div>
                            </div>
                            <div className="adaptiveCardContainer">
                            </div>
                        </div>

                        <div className="footerContainer">
                            <div className={this.state.message.canDownload ? "" : "disabled"}>
                                <div className="buttonContainer">
                                    <Loader id="sendingLoader" className="hiddenLoader sendingLoader" size="smallest" label={this.localize("ExportLabel")} labelPosition="end" />
                                    <TooltipHost content={!this.state.message.sendingCompleted ? "" : (this.state.message.canDownload ? "" : this.localize("ExportButtonProgressText"))} calloutProps={{ gapSpace: 0 }}>
                                        <Button icon={downloadIcon} disabled={!this.state.message.canDownload || !this.state.message.sendingCompleted} content={this.localize("ExportButtonText")} id="exportBtn" onClick={this.onExport} primary />
                                    </TooltipHost>
                                </div>
                            </div>
                            {this.state.message.isScheduled && !this.state.message.sendingCompleted && (
                                <>
                                    <div className="scheduledButtonContainer">
                                        <Loader id="sendingLoader" className="hiddenLoader sendingLoader" size="smallest" label={"Edit"} labelPosition="end" />
                                        <TooltipHost>
                                            <Button content={"Edit"} id="cancelBtn" onClick={this.onEditScheduledTask} secondary />
                                        </TooltipHost>
                                    </div>
                                    <div className="scheduledButtonContainer">
                                        <Loader id="sendingLoader" className="hiddenLoader sendingLoader" size="smallest" label={"Redraft"} labelPosition="end" />
                                        <TooltipHost>
                                            <Button content={"Redraft"} id="cancelBtn" onClick={this.onCancelScheduledTask} primary />
                                        </TooltipHost>

                                    </div>
                                    
                                </>
                            )}
                        </div>
                    </div>
                );
            }
            else if (this.state.page === "SuccessPage") {
                return (
                    <div className="taskModule">
                        <div className="formContainer">
                            <div className="displayMessageField">
                                <br />
                                <br />
                                <div><span><Icon className="iconStyle" name="stardust-checkmark" xSpacing="before" size="largest" outline /></span>
                                    <h1>{this.localize("ExportQueueTitle")}</h1></div>
                                <span>{this.localize("ExportQueueSuccessMessage1")}</span>
                                <br />
                                <br />
                                <span>{this.localize("ExportQueueSuccessMessage2")}</span>
                                <br />
                                <span>{this.localize("ExportQueueSuccessMessage3")}</span>
                            </div>
                        </div>
                        <div className="footerContainer">
                            <div className="buttonContainer">
                                <Button content={this.localize("CloseText")} id="closeBtn" onClick={this.onClose} primary />
                            </div>
                        </div>
                    </div>

                );
            }
            else {
                return (
                    <div className="taskModule">
                        <div className="formContainer">
                            <div className="displayMessageField">
                                <br />
                                <br />
                                <div><span><Icon className="iconStyle" name="stardust-close" xSpacing="before" size="largest" outline /></span>
                                    <h1 className="light">{this.localize("ExportErrorTitle")}</h1></div>
                                <span>{this.localize("ExportErrorMessage")}</span>
                            </div>
                        </div>
                        <div className="footerContainer">
                            <div className="buttonContainer">
                                <Button content={this.localize("CloseText")} id="closeBtn" onClick={this.onClose} primary />
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }

    public onOpenTaskModule = (event: any, url: string, title: string) => {

        let taskInfo: ITaskInfo = {
        url: url,
        title: title,
        height: 660,
        width: 1000,
        fallbackUrl: url,
        }

        microsoftTeams.tasks.updateTask(taskInfo);
    }
    

    private onClose = () => {
        microsoftTeams.tasks.submitTask();
    }

    private close = (isEditSchedule?: boolean) => {
        if (isEditSchedule) {
            microsoftTeams.tasks.submitTask("editing");
        }
        microsoftTeams.tasks.submitTask();
    }

    private onExport = async () => {
        let spanner = document.getElementsByClassName("sendingLoader");
        spanner[0].classList.remove("hiddenLoader");
        let payload = {
            id: this.state.message.id,
            teamId: this.state.teamId
        };
        await exportNotification(payload).then(() => {
            this.setState({ page: "SuccessPage" });
        }).catch(() => {
            this.setState({ page: "ErrorPage" });
        });
    }

    private onCancelScheduledTask = async () => {
        await cancelScheduledMessage(this.state.message.id).then(() => {
            this.onClose();
        }).catch(() => {
            //onClose???
            this.setState({ page: "ErrorPage" });
        });
    }

    private onEditScheduledTask = async () => {
        // let url = getBaseUrl() + "/newmessage/scheduled/" + this.state.message.id + "?locale={locale}";
        // this.onOpenTaskModule(null, url, this.localize("EditMessage"));
        this.close(true);
    }

    private getItemList = (items: string[]) => {
        let resultedTeams: IListItem[] = [];
        if (items) {
            resultedTeams = items.map((element) => {
                const resultedTeam: IListItem = {
                    header: element,
                    media: <Image src={ImageUtil.makeInitialImage(element)} avatar />
                }
                return resultedTeam;
            });
        }
        return resultedTeams;
    }

    private renderAudienceSelection = () => {
        let recipents: JSX.Element[] = []
        if (this.state.message.teamNames && this.state.message.teamNames.length > 0) {
            recipents.push(
                <div key="teamNames"> <span className="label">{this.localize("TeamsLabel")}</span>
                    <List items={this.getItemList(this.state.message.teamNames)} />
                </div>
            );
        }
        if (this.state.message.rosterNames && this.state.message.rosterNames.length > 0) {
            recipents.push(
                <div key="rosterNames"> <span className="label">{this.localize("TeamsMembersLabel")}</span>
                    <List items={this.getItemList(this.state.message.rosterNames)} />
                </div>);
        }
        if (this.state.message.groupNames && this.state.message.groupNames.length > 0) {
            recipents.push(
                <div key="groupNames" > <span className="label">{this.localize("GroupsMembersLabel")}</span>
                    <List items={this.getItemList(this.state.message.groupNames)} />
                </div>);
        }
        if (this.state.message.usersListFile || this.state.message.userNames && this.state.message.userNames.length > 0) {
            recipents.push(
                <div key="userNames" > <span className="label">{"User(s): "}</span>
                    {this.state.message.usersListFile && (<a target="_blank" href={"/api/Content/getUsersAudience?fileName=" + this.state.message.usersListFile}>{this.state.message.usersListFile}</a>)}
                    <List items={this.getItemList(this.state.message.userNames!)} />
                </div>);
        }
        if (this.state.message.allUsers) {
            recipents.push(
                <div>
                    <h3>{this.localize("SendToAllUsers")}</h3>
                </div>);
        } else {
            recipents.push(<div></div>);
        }

        return (<>{recipents}</>)
    }

    private renderErrorMessage = () => {
        if (this.state.message.errorMessage) {
            return (
                <div>
                    <h3>{this.localize("Errors")}</h3>
                    <span>{this.state.message.errorMessage}</span>
                </div>
            );
        } else {
            return (<div></div>);
        }
    }

    private renderWarningMessage = () => {
        if (this.state.message.warningMessage) {
            return (
                <div>
                    <h3>{this.localize("Warnings")}</h3>
                    <span>{this.state.message.warningMessage}</span>
                </div>
            );
        } else {
            return (<div></div>);
        }
    }
}

const StatusTaskModuleWithTranslation = withTranslation()(StatusTaskModule);
export default StatusTaskModuleWithTranslation;