import { IColumn, ActionButton, Dropdown, Icon, IconButton, IDropdownOption, IIconProps, DefaultButton } from "@fluentui/react";
import * as microsoftTeams from "@microsoft/teams-js";
import { Button, IconProps, Image, List, Loader } from "@stardust-ui/react";
import {
    ArcElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip
} from 'chart.js';
import { TFunction } from "i18next";
import * as AdaptiveCards from "adaptivecards";
import * as React from 'react';
import { Bar, Doughnut, Scatter } from "react-chartjs-2";
import { withTranslation, WithTranslation } from "react-i18next";
import { exportNotification, getSentMessageViews, getSentNotification } from "../../apis/messageListApi";
import { formatDate, formatDuration, formatNumber } from "../../i18n";
import { ImageUtil } from "../../utility/imageutility";
import { IListItem } from "../StatusTaskModule/statusTaskModule";
import './singleMessageReport.scss';
import { getInitAdaptiveCard, setActions, setCardAuthor, setCardImageLink, setCardStyle, setCardSummary, setCardTitle, setCardVideoLink } from "../AdaptiveCard/adaptiveCard";
import { markDownToHtml } from '../../helpers/formattingHelper'

interface MessageViewTimes {
    date: string;
    time: string;
}

interface IState {
    notification: any[];
    duration: string;
    summary: any;
    columns: any[];
    loading: boolean;
    pageNumber: number;
    pageToken: any[];
    messageViewTimes: MessageViewTimes[];
    message: IMessage;
    teamId?: string;
}

interface IMessageError {
    message: string;
    count: number;
}

export interface IAction {
    title: string,
    link: string
}

export interface IMessage {
    id: string;
    title: string;
    acknowledgements?: string;
    reactions?: string;
    responses?: string;
    succeeded?: string;
    failed?: string;
    unknown?: string;
    sentDate?: string;
    imageLink?: string;
    videoLink?: string;
    videoImageLink?: string;
    summary?: string;
    author?: string;
    updateType?: string;
    buttonLink?: string;
    buttonTitle?: string;
    teamNames?: string[];
    rosterNames?: string[];
    groupNames?: string[];
    userNames?: string[];
    allUsers?: boolean;
    sendingStartedDate?: string;
    sendingDuration?: string;
    errorMessage?: string;
    warningMessage?: string;
    canDownload?: boolean;
    sendingCompleted?: boolean;
    isScheduled?: boolean;
    scheduledDate?: string;
    sender?: string,
    creator?: string,
    usersListFile?: string;
    actions: IAction[];
    messageErrors: IMessageError[];
}

export interface ISingleMessageReportProps extends WithTranslation {
    notification: any;
    goBack: any;
}

class SingleMessageReport extends React.Component<ISingleMessageReportProps, IState> {
    private card: any;
    readonly localize: TFunction;
    private initMessage = {
        id: "",
        title: "",
        actions: [],
        messageErrors: []
    };
    constructor(props: ISingleMessageReportProps) {
        super(props);
        this.localize = this.props.t;
        this.card = getInitAdaptiveCard(this.props.t);
        this.state = {
            notification: this.props.notification,
            summary: null,
            duration: "",
            columns: [],
            loading: true,
            pageNumber: 1,
            pageToken: [{ nextPartitionKey: "", nextRowKey: "" }],
            messageViewTimes: [],
            message: this.initMessage,
            teamId: '',
        }
    }

    private getItem = async (response: any) => {
        try {
            response.data.sendingDuration = formatDuration(response.data.sendingStartedDate, response.data.sentDate);
            response.data.sendingStartedDate = formatDate(response.data.sendingStartedDate);
            response.data.sentDate = formatDate(response.data.sentDate);
            response.data.succeeded = formatNumber(response.data.succeeded);
            response.data.failed = formatNumber(response.data.failed);
            response.data.unknown = response.data.unknown && formatNumber(response.data.unknown);
            this.setState({
                message: response.data
            });
        } catch (error) {
            return error;
        }
    }

    private onExport = async () => {
        let spanner = document.getElementsByClassName("sendingLoader");
        spanner[0].classList.remove("hiddenLoader");
        let payload = {
            id: this.state.message.id,
            teamId: this.state.teamId
        };

        console.log(payload);
        await exportNotification(payload).then(() => {
            //this.setState({ page: "SuccessPage" });
            spanner[0].classList.add("hiddenLoader");
        }).catch(() => {
            //this.setState({ page: "ErrorPage" });
            spanner[0].classList.add("hiddenLoader");
        });
    }

    public async componentDidMount() {
        microsoftTeams.initialize();
        microsoftTeams.getContext((context) => {
            this.setState({
                teamId: context.teamId,
            });
        });

        const notificationSummaryResponse = await getSentNotification(this.props.notification.id, true);
        //const sentMessageViews = await getSentMessageViews(this.props.notification.id);

        const summaryData = notificationSummaryResponse.data;
        //const sentMessageViewData = sentMessageViews.data;

        if (this.props.notification.id) {
            let id = this.props.notification.id;
            this.getItem(notificationSummaryResponse).then(() => {

                var card;

                setCardTitle(this.card, this.state.message.title);
                setCardImageLink(this.card, this.state.message.imageLink);
                setCardVideoLink(this.card, this.state.message.videoLink, this.state.message.videoImageLink);
                setCardSummary(this.card, this.state.message.summary);
                setCardAuthor(this.card, this.state.message.author);
                setCardStyle(this.card, this.state.message.updateType);
                setActions(this.card, this.state.message.actions);
                card = this.card;

                let adaptiveCard = new AdaptiveCards.AdaptiveCard();
                AdaptiveCards.AdaptiveCard.onProcessMarkdown = function (text, result) {
                    result.outputHtml = markDownToHtml(text);
                    result.didProcess = true;
                };

                adaptiveCard.parse(card);
                let renderedCard = adaptiveCard.render() as HTMLElement;
                document.getElementsByClassName('adaptiveCardContainer')[0].appendChild(renderedCard);
                let link = this.state.message.buttonLink;
                adaptiveCard.onExecuteAction = function (action) { window.open(link, '_blank'); }
            });
        }



        const columns: IColumn[] = [
            {
                key: 'column1',
                name: 'User',
                fieldName: 'user',
                isSorted: true,
                isResizable: true,
                minWidth: 100,
                maxWidth: 180,
                data: 'string',
                onRender: (item: any) => {
                    return (
                        <span>{item.userId}</span>
                    )
                },
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Status',
                fieldName: 'status',
                minWidth: 50,
                maxWidth: 80,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'number',
                onRender: (item: any) => {
                    return <span>{item.statusCode}</span>;
                },
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Viewed?',
                fieldName: 'viewed',
                minWidth: 50,
                maxWidth: 80,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                onRender: (item: any) => {
                    return <span>{item.isViewed ? item.isViewed.toString() : "false"}</span>;
                },
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'View Date',
                fieldName: 'viewDate',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                data: 'string',
                onRender: (item: any) => {
                    return <span>{item.dateViewed}</span>;
                },
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Reaction',
                fieldName: 'reaction',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                data: 'number',
                onRender: (item: any) => {
                    return <span>{item.reactionString}</span>;
                },
                isPadded: true,
            },
            {
                key: 'column6',
                name: 'Buttons Clicked',
                fieldName: 'buttonsClicked',
                minWidth: 70,
                maxWidth: 150,
                isResizable: true,
                data: 'string',
                onRender: (item: any) => {
                    return <span>{item.buttonsClicked}</span>;
                },
                isPadded: true,
            }
        ];

        this.setState({
            columns: columns,
            duration: formatDuration(summaryData.sendingStartedDate, summaryData.sentDate),
            summary: summaryData,
            loading: false,
        });
    }

    public render(): JSX.Element {
        ChartJS.register(
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            ArcElement,
            Tooltip,
            Title,
            Legend,
        );

        const previousIcon: IIconProps = { iconName: 'ChevronLeft' };
        const downloadIcon: IconProps = { name: 'download', size: "medium" };

        if (this.state.loading) {
            return (
                <div className="calendar-loader">
                    <Loader />
                </div>
            )
        } else {
            const createdDate = formatDate(this.state.summary.createdDateTime);
            const startedSendingDate = formatDate(this.state.summary.sendingStartedDate);
            const sentDate = formatDate(this.state.summary.sentDate);
            const notViewedCount = this.state.summary.succeeded - this.state.summary.views;

            const doughnutOptions = {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'top' as const,
                    },
                    title: {
                        display: true,
                        text: 'Failed Messages'
                    }
                }
            }

            const doughnutData = {
                labels: this.state.message.messageErrors.map((e) => JSON.parse(e.message).error.code),
                datasets: [
                    {
                        label: 'Total',
                        data: this.state.message.messageErrors.map((e) => e.count),
                        borderColor: ['#02a5f2', '#8fce00', '#F39800'],
                        backgroundColor: ['#02a5f2', '#8fce00', '#F39800'],
                    }
                ],
            };

            const barOptions = {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Message Status'
                    }
                }
            }

            const barData = {
                labels: ['Sent', 'Views', 'Clicks'],
                datasets: [
                    {
                        data: [this.state.summary.succeeded, this.state.summary.views, this.state.summary.buttonClicks],
                        borderColor: ['#02a5f2', '#8fce00', '#F39800'],
                        backgroundColor: ['#02a5f2', '#8fce00', '#F39800'],
                        barThickness: 50,
                    },
                ],
            };

            
            // let scatterArray: Object[] = [];
            // if (this.state.messageViewTimes.length > 0) {

            //     this.state.messageViewTimes.forEach((e) => {
            //         scatterArray.push({"x": new Date(e.date), "y": new Date(e.time)})
            //     })
            // }

            // const scatterOptions = {
            //     maintainAspectRatio: false,
            //     plugins: {
            //         legend: {
            //             display: true
            //         },
            //         title: {
            //             text: "Message Timeline of Views",
            //             display: true,
            //         }
            //     },
            //     scales: {
            //         x:{
            //             time: {
            //                 unit: "day"
            //             },
            //             title: {
            //                 display: true,
            //                 text: "Date Viewed",
            //             }        
            //         },
            //         y: {
            //             title: {
            //                 display: true,
            //                 text: "Time Viewed",
            //             }                        
            //         },
            //     },
            // };

            // const scatterData = {
            //     datasets: [
            //         {
            //         label: 'Message Views by Time',
            //         data: scatterArray,
            //         backgroundColor: 'rgba(255, 99, 132, 1)',
            //         },
            //     ],
            // };

            return (
                <div className="report-container">
                    <div style={{ marginBottom: "20px" }} >
                        <Button className="report-back" onClick={this.props.goBack} secondary>Back</Button>
                        <h2 id="notificationSummaryHeader" style={{ display: "inline", verticalAlign: "middle", marginLeft: "20px" }} >Notification Summary</h2>
                        <div className="buttonContainer" style={{display: "inline-block", float: "right"}} >
                            <Loader id="sendingLoader" className="hiddenLoader sendingLoader" size="smallest" label={this.localize("ExportLabel")} labelPosition="end" />
                            <Button icon={downloadIcon} disabled={!this.state.message.canDownload || !this.state.message.sendingCompleted} content="Export" id="exportBtn" onClick={this.onExport} secondary/>
                        </div>
                    </div>
                    {this.state.summary &&
                        <>
                            <div className="report-charts">
                                <div className="top-charts">
                                    <div className="chart">
                                        <div className="adaptiveCardContainer" style={{ maxWidth: "450px", border: "1px solid #c7c7c7" }} >
                                        </div>
                                        {/*    <Doughnut*/}
                                        {/*        options={viewOptions}*/}
                                        {/*        data={viewData}*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                        {/*<div className="chart">*/}
                                        {/*    <Doughnut*/}
                                        {/*        options={successOptions}*/}
                                        {/*        data={successData}*/}
                                        {/*    />*/}
                                    </div>
                                    <div className="chart" style={{ border: "1px solid #c7c7c7", background: "white", padding: "15px", marginLeft: "10px", minWidth: "448px" }} >
                                        <Bar
                                            options={barOptions}
                                            data={barData}
                                        />
                                    </div>
                                    {
                                        this.state.summary.failed > 0 &&
                                        <div className="chart" style={{ border: "1px solid #c7c7c7", background: "white", padding: "15px", marginLeft: "10px", minWidth: "448px" }}>
                                            <Doughnut
                                                options={doughnutOptions}
                                                data={doughnutData}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="bottom-chart chart">
                                    {/* <Scatter 
                                        options={scatterOptions}
                                        data={scatterData}
                                    /> */}
                                </div>
                        </div>
                        <hr/>
                            <div className="report-stats">
                                {/*<div className="report-stats-column">*/}
                                {/*<div className="adaptiveCardContainer" style={{ maxWidth: "450px", border: "1px solid #c7c7c7" }} >*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="report-stats-column">
                                    <div className="report-stat"><span>Title: </span>{this.state.summary.title}</div>
                                    <div className="report-stat"><span>Created By: </span> {this.state.summary.creator}</div>
                                    <div className="report-stat"><span>Created By UPN: </span>{this.state.summary.creatorUpn}</div>
                                    <div className="report-stat"><span>Created Date: </span>{createdDate}</div>
                                    <div className="report-stat"><span>Sent By: </span>{this.state.summary.sender}</div>
                                    <div className="report-stat"><span>Started Sending Date: </span>{startedSendingDate}</div>
                                    <div className="report-stat"><span>Completed Sent Date: </span>{sentDate}</div>
                                    <div className="report-stat"><span>Duration: </span>{this.state.duration}</div>
                                    <div className="report-stat">{this.renderAudienceSelection()}</div>
                                </div>
                                <div className="report-stats-column">
                                    <div className="report-stat"><span>Succeeded: </span>{this.state.summary.succeeded}</div>
                                    <div className="report-stat"><span>Failed: </span>{this.state.summary.failed}</div>
                                    {this.state.summary.warningMessage &&
                                        <div className="report-stat"><span>Failed Reason: </span>{this.state.summary.warningMessage}</div>
                                    }
                                    <div className="report-stat"><span>Total Views: </span>{this.state.summary.views} ({this.state.summary.popularity * 100}%)</div>
                                    <div className="report-stat"><span>Total Reactions: </span>{this.state.summary.reactionCount}</div>
                                    <div className="report-stat"><span>Total Button Clicks: </span>{this.state.summary.buttonClicks}</div>
                                    <div className="report-stat"><span>Is Important: </span>{this.state.summary.isImportant.toString()}</div>
                                    <div className="report-stat"><span>Was Scheduled: </span>{this.state.summary.isScheduled.toString()}</div>

                                </div>
                                <div className="report-stats-column">

                                </div>
                            </div>

                        </>
                    }
                </div>
            )
        }
    }

    private getItemList = (items: string[]) => {
        let resultedTeams: IListItem[] = [];
        if (items) {
            resultedTeams = items.map((element) => {
                const resultedTeam: IListItem = {
                    header: element,
                    media: <Image src={ImageUtil.makeInitialImage(element)} avatar />
                }
                return resultedTeam;
            });
        }
        return resultedTeams;
    }

    private renderAudienceSelection = () => {
        let recipents: JSX.Element[] = []
        if (this.state.summary.teamNames && this.state.summary.teamNames.length > 0) {
            recipents.push(
                <div key="teamNames"> <span className="label">{this.localize("TeamsLabel")}</span>
                    <List items={this.getItemList(this.state.summary.teamNames)} />
                </div>
            );
        }
        if (this.state.summary.rosterNames && this.state.summary.rosterNames.length > 0) {
            recipents.push(
                <div key="rosterNames"> <span className="label">{this.localize("TeamsMembersLabel")}</span>
                    <List items={this.getItemList(this.state.summary.rosterNames)} />
                </div>);
        }
        if (this.state.summary.groupNames && this.state.summary.groupNames.length > 0) {
            recipents.push(
                <div key="groupNames" > <span className="label">{this.localize("GroupsMembersLabel")}</span>
                    <List items={this.getItemList(this.state.summary.groupNames)} />
                </div>);
        }
        if (this.state.summary.usersListFile || this.state.summary.userNames && this.state.summary.userNames.length > 0) {
            recipents.push(
                <div key="userNames" > <span className="label">{"User(s): "}</span>
                    {this.state.summary.usersListFile && (<a target="_blank" href={"/api/Content/getUsersAudience?fileName=" + this.state.summary.usersListFile}>{this.state.summary.usersListFile}</a>)}
                    <List items={this.getItemList(this.state.summary.userNames!)} />
                </div>);
        }
        if (this.state.summary.allUsers) {
            recipents.push(
                <div>
                    <h3>{this.localize("SendToAllUsers")}</h3>
                </div>);
        } else {
            recipents.push(<div></div>);
        }

        return (<>{recipents}</>)
    }

}

const SingleMessageReportWithTranslation = withTranslation()(SingleMessageReport);
export default SingleMessageReportWithTranslation;